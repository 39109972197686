import React from 'react'
import { makeStyles, Grid, Slide, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import olas from '../../../../../assets/olas.svg'

const useStyles = makeStyles(theme => ({
  root:{
    position:'absolute',
    zIndex:1,
    width:'100%',
    height:'100vh',
    paddingTop:100
  },
  container:{
    width:1400,
    margin:'auto',
    color:'white',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('xl')]: {
      width:1400,
    },
    [theme.breakpoints.only('lg')]: {
      width:1100,
      //background:'red'
    },
    [theme.breakpoints.only('md')]: {
      //background:'green',
      width:'100%',
      padding:'0px 32px'
    },
  },
  subcontainer:{
    position:'absolute',
    top:'50%',
    left:'50%',
    width:1200,
    transform:'translate(-50%, 40px)',
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:900,
    },
    [theme.breakpoints.only('xs')]: {
      top:'30%',
      //left:'40%',
      //background:'red'
    },
  },
  subtitle:{
    fontSize:36,
    fontWeight:500,
    //fontFamily:theme.typography.fontFamily,
    [theme.breakpoints.only('lg')]: {
      fontSize:32,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:26,
      
    },
    [theme.breakpoints.only('sm')]: {
      width:600,
      //background:'red',
      fontSize:28,
    },
    [theme.breakpoints.only('xs')]: {
      width:200,
      fontSize:20,
    },
  },
  olas_container:{
    position:'absolute',
    bottom:0,
    left:0
  }
}))

const ScreenB = props => {

  const classes = useStyles()
  const {scroll, range, fade_in, language} = props

  const content = contentData[language]

  return(
    <div className={classes.root}>
      <div className={classes.container}>
        
          <div className={classes.subcontainer}>
            <Slide direction='right' in={scroll >= (range.min + range.offset)} timeout={1200}>
              <div>
                <Grid container>
                  <Grid item xs={12}  >
                    <div className={classes.subtitle} style={{color:fade('#FFF', fade_in)}}>
                      {content.data}</div>
                  </Grid>
                </Grid>
              </div>
            </Slide>
          </div>
              
      </div>
      
    </div>
  )
}

export default ScreenB

const contentData = {
  english:{
    title:'Meet',
    data:'We design solutions, services, products and technology for growth and transformation through a deep understanding of human behavior.',
    //data:'We are a company of professionals from different backgrounds like medicine, human-centered design, engineering, innovation, hardware, software, digital, business strategy, management, consulting, marketing, sales, luxury, arts and research.'
  }
}