import React, { useEffect } from 'react'
import {Button, Fade, Grid, Icon, makeStyles, Slide, Typography, Zoom } from '@material-ui/core'
import logo from '../../assets/new_logos/ALTHAR_LOGO_CLARO_TEXTO-05.svg'
import prueba from '../../assets/punch.png'
import video from '../../assets/masterclass.mp4'
import video2 from '../../assets/Eventos.mp4'
import useWorkView from './useWorkView'


const WorkView = ({language}) =>{

  const classes = useStyles()

  const {scroll_position, actions, } = useWorkView({})

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  //console.log(scroll_position)
  
  return(
    <div className={classes.root}>  
      <div className={classes.subroot} >
        <Slide direction='right' in={true}  timeout={1200}>
          <div className={classes.title_container}>
              <img src={logo} alt='' className={classes.logo}/>
              <Typography variant='h2' className={classes.title} >WORK</Typography>
          </div>
        </Slide> 
        <div className={classes.our_work_container} >
        <Zoom in timeout={2000}>
          <Typography className={classes.our_work} variant='h3'>Our work</Typography>
        </Zoom>
        </div>
        {scroll_position < 600 ? <div className={classes.video_container} >
          <video className={classes.video} controls autoPlay  >
              <source src={video} type="video/mp4" />
          </video>
        </div> : null}
        {scroll_position > 650 ? 
        <Zoom in={scroll_position > 650} timeout={700} >
          <div className={classes.video_container}  >
            <video className={classes.video} controls autoPlay  >
                <source src={video2} type="video/mp4" />
            </video>
          </div> 
        </Zoom>: null}
      </div>
    </div>
  )

}


const useStyles = makeStyles(theme => ({
  root:{
    position:'relative',
    background:theme.palette.primary.main,
    height:'1800px',
  },
  subroot:{
    width:'100%',
    height:'100vh',
    position:'sticky',
    top:'0px',
  },
  our_work_container:{
    [theme.breakpoints.up('md')]: {
      display:'none'
    },
  },
  title:{
    //color:theme.palette.primary.main,
    //marginLeft:24,
    marginTop:52,
    fontWeight:600,
    [theme.breakpoints.down('sm')]: {
      display:'none'
    },
  },
  our_work:{
    marginLeft:24, fontWeight:600, marginTop:-24,
    [theme.breakpoints.only('xs')]: {
      fontSize:'2rem',
      marginTop:-12,
      marginLeft:16
    },
  },
  title_container:{
    position:'relative',
    display:'flex',
    alignItems:'center'
  },
  video:{
    height:'100%',
    width:'100%',

  },
  video_container:{
    position:'absolute',
    bottom:0,
    left:0,
    width:'100%',
    //background:'red',
    height:'70vh',
    [theme.breakpoints.down('sm')]: {
      bottom:'10%',
      
    },
    [theme.breakpoints.down('xs')]: {
      bottom:'20%',
      
    },
  },
  video_container2:{
    //marginLeft:40,
    marginTop:400,
    width:'100%',
    [theme.breakpoints.only('lg')]: {
      marginTop:250
    },
    [theme.breakpoints.down('sm')]: {
      marginTop:100
    },
    
  },
  container:{
    width:1000,
    height:350,
    background:'white',
    margin:'auto'
  },
  logo:{
    width:400,
    [theme.breakpoints.down('sm')]: {
      width:300,
    },
    [theme.breakpoints.down('sm')]: {
      width:250,
    },
    
    //paddingBottom:12
  },
  
}))

const contentData = {
  english:{
    sectionA:{
      title:'Mission',
      data:'We are transforming health with technology, data, design and frenzy for life.',
      timeout:2400
    },
    sectionB:{
      title:'Courage',
      data:'Enthusiasm, true conviction and fearless believe lead our standards way beyond expectations.',
      timeout:3000
    },
    sectionC:{
      title:'Character',
      data:'Mixing our talents and moral quality; We have the right motives to achieve greater results.',
      timeout:3600
    },
    sectionD:{
      title:'Integrity',
      data:'We always do the right things, building up strong and lasting working relationships.',
      timeout:4200
    },
    sectionE:{
      title:'Innovation',
      data:'We strive toward to create and design.',
      timeout:4800
    }
  }
}

export default WorkView