import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import image from '../../assets/ALTHAR_telefarmcia.png'

const Telepharmacy = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <div className={classes.title_container} >
                <Typography className={classes.title} variant='h2'>Telepharmacy</Typography>
            </div>
            <div className={classes.challenge}>
                <Typography className={classes.subtitle} variant='subtitle1'>Challenge.</Typography>
                <Typography className={classes.text}>
                    Help CeluFarma, an Althar start-up in residence,
                    build a venturing strategy for its prescription 
                    home-delivery system and virtual franchise model.
                </Typography>
            </div>
            <div className={classes.solution}>
                <Typography className={classes.subtitle} variant='subtitle1'>Solution.</Typography>
                <Typography className={classes.text}>
                    CeluFarma now delivers thousands of prescriptions
                    every month.
                </Typography>
            </div>
            <div className={classes.result}>
                <Typography className={classes.subtitle} variant='subtitle1'>Result.</Typography>
                <Typography className={classes.text}>
                    A refined brand vision, strategy, and identity across channels,
                    as well as a mobile-first PWA and app, a private dashboard for
                    CeluFarma customers, and a suite of physical products.
                </Typography>
            </div>
            {/* <div className={classes.img_container}>
                <img src={image} alt='' className={classes.img} />
            </div> */}
            <img src={image} alt='' className={classes.img} />
            <div className={classes.line_container} style={{top:'50%', left:'18%'}}>
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>
                <div className={classes.line} style={{width:240, marginTop:16, marginLeft:-7}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'56%', left:'16%'}}>
                <div className={classes.line2} style={{width:30, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:340, marginTop:-10, marginLeft:-3}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'40%', right:'12%'}}>
                <div className={classes.line} style={{width:340, marginTop:-16, marginRight:-5}}></div> 
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>        
            </div>
        </div>
    )
}

export default Telepharmacy

const useStyles = makeStyles(theme => ({
    root:{
      background:theme.palette.secondary.main,
      //background:'red',
      height:'100vh',
      padding:'5%',
      color:'white',
      position:'relative'
    },
    title_container:{
        position:'absolute',
        right:'5%',
        top:'10%',
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            right:'auto',
            top:'auto',
            marginBottom:12,
            marginTop:100
        },
    },
    title:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
          },
    },
    subtitle:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.2rem'
          },
    },
    text:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.9rem'
          },
    },
    line_container:{
        position:'absolute',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
    },
    line:{
        height:0.5,
        background:'white',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        //width:180,
    },
    line2:{
        height:0.5,
        background:'white',
        width:60,
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        
    },
    challenge:{
        position:'absolute',
        top:'26%',
        //bottom:'50%',
        left:'5%',
        //paddingTop:'50%',
        //paddingTop:'5%',
        maxWidth:360,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'green',
            top:'auto',
            left:0,
            marginTop:12
            
          },
    },
    solution:{
        position:'absolute',
        top:'42%',
        right:'5%',
        maxWidth:240,
        textAlign:'right',
        //background:'black'
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'blue',
            top:'auto',
            right:0,
            left:0,
            textAlign:'left',
            margin:'12px 0px'
        },
        
        
    },
    result:{
        position:'absolute',
        //top:'63%',
        bottom:'18%',
        //right:'30%',
        left:'12%',
        maxWidth:340,
        [theme.breakpoints.down('md')]:{
            //left:'5%'
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            bottom:'auto',
            left:0, 
        },
        
    },
    img_container:{
        position:'absolute',

        top:-10,
        bottom:10,
        height:100,
        //left:'50%',
        background:'black'
    },
    img:{
        //width:'100%',
        //height:'100%',
        //transform:'rotate(10deg)',
        objectFit:'cover',
        position:'absolute',
        top:0,
        left:'35%',
        //right:'15%',
        //right:'20%',
        //background:'black',
        width:'35%',
        [theme.breakpoints.down('md')]:{
            //transform:'scale(0.8) rotate(25deg)',
            //right:'10%',
            //top:-20
            top:'20%',
            left:'40%'
        },
        [theme.breakpoints.down('sm')]:{
            //transform:'scale(0.8) rotate(25deg)',
            right:'5%',
            top:'-8%'
        },
        [theme.breakpoints.down('xs')]:{
            display:'none'
        }

        //objectPosition:'center'
        //bottom:20
    }
    
  }))