import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import image from '../../assets/ALTHAR_WEB_VR-for-Oncology-Management.png'

const VR = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <div className={classes.title_container}>
                <Typography className={classes.title} variant='h2'>VR for Oncology</Typography>
                <Typography className={classes.title} variant='h2'>Management</Typography>
            </div>   
            <div className={classes.challenge}>
                <Typography className={classes.subtitle} variant='subtitle1'>Challenge.</Typography>
                <Typography className={classes.text}>
                    A Mexican private oncology institute was in need to add
                    value for its patients in chemotherapy schemes. The current
                    program provokes sad and rejection feelings, according to 
                    our research.
                </Typography>
            </div>
            <div className={classes.solution}>
                <Typography className={classes.subtitle} variant='subtitle1'>Solution.</Typography>
                <Typography className={classes.text}>
                    We create, design and develop the first Latin American
                    virtual reality program for oncology patients in
                    chemotherapy.
                </Typography>
            </div>
            <div className={classes.result}>
                <Typography className={classes.subtitle} variant='subtitle1'>Result.</Typography>
                <Typography className={classes.text}>
                    100% adherence to the chemotherapy program. Pain scales
                    with 30% reduction and stress levels with 40% reduction 
                    (cortisol measured).
                </Typography>
            </div>
            {/* <div className={classes.img_container}>
                <img src={image} alt='' className={classes.img} />
            </div> */}
            <img src={image} alt='' className={classes.img} />
            <div className={classes.line_container} style={{top:'52%', left:'18%'}}>
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>
                <div className={classes.line} style={{width:240, marginTop:16, marginLeft:-7}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'62%', left:'15%'}}>
                <div className={classes.line2} style={{width:30, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:320, marginTop:-10, marginLeft:-3}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'40%', right:'14%'}}>
                <div className={classes.line} style={{width:300, marginTop:-16, marginRight:-5}}></div> 
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>        
            </div>
        </div>
    )
}

export default VR

const useStyles = makeStyles(theme => ({
    root:{
      background:theme.palette.secondary.main,
      //background:'red',
      height:'100vh',
      padding:'5% 5% 0px',
      color:'white',
      position:'relative'
    },
    title_container:{
        position:'absolute',
        right:'5%',
        top:'10%',
        textAlign:'right',
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            textAlign:'left',
            right:'auto',
            top:'auto',
            marginBottom:12,
            marginTop:50
        },
    },
    title:{
        lineHeight:0.9,
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
          },
    },
    subtitle:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.2rem'
          },
    },
    text:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.9rem'
          },
    },
    line_container:{
        position:'absolute',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
    },
    line:{
        height:0.5,
        background:'white',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        //width:180,
    },
    line2:{
        height:0.5,
        background:'white',
        width:60,
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        
    },
    challenge:{
        position:'absolute',
        //top:'35%',
        bottom:'50%',
        left:'5%',
        //paddingTop:'50%',
        //paddingTop:'5%',
        maxWidth:340,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'green',
            bottom:'auto',
            left:0,
            marginTop:12
            
          },
        
    },
    solution:{
        position:'absolute',
        top:'45%',
        right:'5%',
        maxWidth:280,
        textAlign:'right',
        //background:'black'
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'blue',
            top:'auto',
            right:0,
            left:0,
            textAlign:'left',
            margin:'12px 0px'
        },
        
    },
    result:{
        position:'absolute',
        //top:'63%',
        top:'65%',
        left:'10%',
        maxWidth:390,
        [theme.breakpoints.down('md')]:{
            left:'5%'
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:'auto',
            left:0, 
        },
        
    },
    img_container:{
        position:'absolute',

        top:-10,
        bottom:10,
        height:100,
        //left:'50%',
        background:'black'
    },
    img:{
        //width:'100%',
        //height:'100%',
        //transform:'rotate(25deg)',
        objectFit:'cover',
        position:'absolute',
        top:'25%',
        right:'32%',
        //background:'black',
        width:'30%',
        [theme.breakpoints.down('md')]:{
            
        },
        [theme.breakpoints.down('sm')]:{
            right:'10%',
            top:'35%'
        },
        [theme.breakpoints.down('xs')]:{
            display:'none'
        }

        //objectPosition:'center'
        //bottom:20
    }
    
  }))