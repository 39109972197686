  import React from 'react'
  import { makeStyles, Fade, withWidth } from '@material-ui/core';
  import cx from 'classnames'

  const vector_size = {
    'xs': {width:625,height:197, offset_x:60, offset_y:-30, scale:0.0025},
    'sm': {width:625,height:197, offset_x:50, offset_y:0, scale:0.2},
    'md':{width:625,height:197, offset_x:42, offset_y:-40, scale:0.25},
    'lg': {width:625,height:197, offset_x:40, offset_y:-40, scale:0.35},
    'xl': {width:625,height:197, offset_x:30, offset_y:-40, scale:0.5},
  }

  const useStyles = makeStyles(theme => ({
    svgImage:{
      position:'absolute',
      width:"1124.534px", 
      height:"1124.6px",
      top:'50%',
      right:'0%',
      zIndex:0,
      //transition: theme.transitions.create(['transform','width','height'], {
      //  easing: theme.transitions.easing.easeIn,
      //  duration: 350,
      //}),
      [theme.breakpoints.down('sm')]: {
        display:'none'
      },
    },
  }))

  const CircleLogo =  props => {

    const {range, scroll, max_visibility, width, scale} = props
    const classes = useStyles()

    let logo_size = vector_size[width] 
    let logo_scale = logo_size.scale + scale

    let logo_offset_x = logo_size.offset_x
    let logo_offset_y = logo_size.offset_y
    

    

    return(
    
        <div className={cx({
          [classes.svgImage]:true,
        })} 
          style={{
            visibility: scroll < max_visibility ? 'visible' : 'hidden',
            transform:`translate(${logo_offset_x}%, ${logo_offset_y}%) scale(${logo_scale})`,
          }}
          >
            <Fade direction='right' in={scroll >= (range.min + range.offset)} timeout={2500}>
              <div>
                  <SVGCircle />
              </div>
            </Fade>
          
        </div>
      
      
    )
  }

  export default withWidth()(CircleLogo)


  const SVGCircle = props => {
    return(
    <svg  x="0px" y="0px" opacity={1} width="1124.534px" height="1124.6px">
      <g>
        <g>
          <path fillRule="evenodd" clipRule="evenodd" fill="#00adb5" d="M158.335,953.478
            c102.205,105.538,245.441,171.143,403.984,171.143c158.542,0,301.673-65.604,403.88-171.143H158.335z M774.751,763.071
            c-28.377,0-56.806,6.924-78.465,20.826c-43.268,27.752-43.268,72.842,0,100.593c43.319,27.751,113.557,27.751,156.877,0
            c43.268-27.751,43.268-72.841,0-100.593C831.503,769.995,803.127,763.071,774.751,763.071z M9.164,663.883
            c11.298,62.115,32.854,120.69,62.792,173.851h538.056c-0.052-1.354-0.155-2.655-0.155-4.009
            c0-59.044,73.152-106.945,163.436-106.945c90.232,0,163.385,47.901,163.385,106.945c0,1.354,0,2.654-0.051,4.009h115.951
            c29.939-53.16,51.494-111.735,62.845-173.851H9.164z M138.914,417.349c-9.737,0-19.421,1.822-27.752,5.415l-18.38,8.019
            c-16.713,7.237-38.789,7.237-55.502,0l-18.381-8.019c-0.467-0.156-0.885-0.312-1.301-0.521C6.092,467.02,0,513.932,0,562.302
            c0,4.791,0.052,9.581,0.155,14.318c4.114-0.885,8.072-2.187,11.82-3.801l18.275-7.861c16.713-7.29,38.842-7.29,55.606,0
            l18.327,7.861c16.767,7.289,38.79,7.289,55.556,0l18.38-7.861c16.713-7.29,38.789-7.29,55.503,0l18.379,7.861
            c16.714,7.289,38.79,7.289,55.503,0l18.38-7.861c16.714-7.29,38.79-7.29,55.503,0l18.379,7.861
            c16.766,7.289,38.791,7.289,55.555,0l18.328-7.861c16.766-7.29,38.894-7.29,55.607,0l18.275,7.861
            c16.766,7.289,38.894,7.289,55.658,0l18.275-7.861c16.715-7.29,38.842-7.29,55.607,0l18.275,7.861
            c16.714,7.289,38.895,7.289,55.607,0l18.379-7.861c16.714-7.29,38.79-7.29,55.504,0l18.379,7.861
            c16.713,7.289,38.789,7.289,55.503,0l18.38-7.861c16.766-7.29,38.789-7.29,55.555,0l18.328,7.861
            c16.765,7.289,38.789,7.289,55.555,0l18.327-7.861c16.766-7.29,38.894-7.29,55.659,0l4.01,1.718
            c0.051-1.458,0.104-2.915,0.104-4.374c0-43.631-4.998-86.118-14.422-126.886c-13.017,1.822-26.971,0.313-38.374-4.634
            l-18.274-8.019c-16.766-7.237-38.895-7.237-55.607,0l-18.275,8.019c-16.766,7.237-38.895,7.237-55.66,0l-18.275-8.019
            c-16.713-7.237-38.842-7.237-55.606,0l-18.275,8.019c-16.714,7.237-38.842,7.237-55.606,0l-18.38-8.019
            c-16.714-7.237-38.79-7.237-55.503,0l-18.379,8.019c-16.715,7.237-38.79,7.237-55.504,0l-18.379-8.019
            c-16.713-7.237-38.79-7.237-55.556,0l-18.327,8.019c-16.766,7.237-38.789,7.237-55.555,0l-18.328-8.019
            c-16.765-7.237-38.894-7.237-55.658,0l-18.275,8.019c-16.714,7.237-38.842,7.237-55.607,0l-18.275-8.019
            c-16.713-7.237-38.842-7.237-55.607,0l-18.275,8.019c-16.713,7.237-38.894,7.237-55.606,0l-18.276-8.019
            C158.386,419.171,148.599,417.349,138.914,417.349z M59.929,309.415c-7.758,15.412-14.84,31.24-21.191,47.38h1047.162
            c-6.352-16.141-13.484-31.969-21.295-47.38H59.929z M562.319-0.017c-195.25,0-367.33,99.604-468.131,250.648h936.156
            C929.544,99.534,757.568-0.017,562.319-0.017z"/>
        </g>
      </g>
    </svg>
    )
  }