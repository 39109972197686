import React from 'react'
import { withStyles, fade, Typography, Fade, Slide, Zoom, Collapse } from '@material-ui/core';
import AltharLogo from './components/AltharLogo';
import ScreenB from './components/ScreenB'
import LogoGeneralMask from './components/LogoGeneralMask2';
import cx from 'classnames'
import ScreenD from './components/ScreenD';
import MeetLogo from './components/MeetLogo';
import dummy_image from '../../../../assets/collageAlthar.jpg'
import useSectionB from './useSectionB';

//new images
import image1 from '../../../../assets/PNG/PNG/WEBnegro_18.png'
import image2 from '../../../../assets/PNG/PNG/WEBnegro_19.png'
import image3 from '../../../../assets/PNG/PNG/WEBnegro_20.png'
import image4 from '../../../../assets/PNG/PNG/WEBnegro_21.png'
import image5 from '../../../../assets/PNG/PNG/WEBnegro_22.png'

import video from '../../../../assets/Althar_Credenciales.mp4'
import Manifiesto from '../SectionA/components/Manifiesto';
import olas from '../../../../assets/olas.svg'
import logo_white from '../../../../assets/new_logos/ALTHAR_LOGO_blanco.svg'


const totalHeight = 8300
//const totalHeight = 8300

/* const range_first_screen = {min:0, max:1000, offset:7100, offset2:1000}
const range_waves = {min:0, max:1000, offset:6500, offset2:1000}
const range_second_screen = {min:0, max:1100, offset:8000}
const range_overlay = {min:0, max:1600, offset:9000} */

const SectionB = ({classes, language, scroll_position, actions, offset}) => {

  const {width, height} = useSectionB({scroll_position})

  //althar meet logo y olas
  const range_first_screen = {min:0, max:1000, offset:offset + 100, offset2:1000}
  const range_waves = {min:0, max:1000, offset:offset - 500, offset2:1000}

  //logo althar
  const range_logo_screen = {min:0, max:1600, offset:offset + 100, offset2:1000}

  //parte negra con logo azul
  const range_second_screen = {min:0, max:1100, offset:offset + range_first_screen.max}

  //fondo azul con logo 
  const range_third_screen = {min:0, max:800, offset:range_second_screen.offset + range_second_screen.max}

  //logo mask y video
  const range_video_screen = {min:0, max:2500, offset:range_third_screen.offset + range_third_screen.max}
  const range_overlay = {min:0, max:1600, offset:offset + 2000}

  //althar logo and meet logo
  let fade_first_screen = actions.onFadeText(range_first_screen)
  let scale_first_position = actions.onScaleSVG(range_first_screen)

  let fade_waves = actions.onFadeText(range_waves)
  let fade_second_screen = actions.onFadeText(range_second_screen)
  
  let overlay_fade = actions.onFadeText(range_overlay)
  let overlay_scale = actions.onScaleSVG(range_overlay)

  const max_visibility = range_second_screen.max + range_second_screen.offset


  return(
    <div className={cx({
      [classes.root]:true,
      [classes.rootB]: scroll_position > range_second_screen.offset + range_second_screen.max ,
    })} 
    //ref={(ref) => {this.myContainer = ref}}
    > 
      <div className={classes.emptySpace} />
      <div className={classes.container} style={{overflow:'hidden', }}>
        <div className={classes.subcontainer} style={{
          visibility:scroll_position < max_visibility + 1000 ? 'visible' : 'hidden',
          overflow:'hidden', 
          //background:'black',
          }}>
            <ScreenB language={language} scroll={scroll_position} range={range_first_screen} fade_in={fade_first_screen}/>
            <ScreenD language={language} scroll={scroll_position} range={range_second_screen} fade_in={fade_second_screen}/>
            {/*<div style={{position:'absolute', top:'50%', width:'100%', height:'1px', zIndex:90000, background:'white'}}/>*/}
            <AltharLogo 
              _width={width}
              _height={height}
              max_visibility={max_visibility}
              scroll={scroll_position} 
              range={range_logo_screen}  
              scale={scale_first_position}/> 
            <MeetLogo 
              _width={width}
              _height={height}
              fade={fade_first_screen}
              max_visibility={max_visibility}
              scroll={scroll_position} 
              range={range_first_screen}  
              scale={scale_first_position}/> 
            <Fade direction='right' in={fade_waves} timeout={2500}>
              <img src={olas} className={classes.olas} />
            </Fade>
        </div>    
        {scroll_position > range_third_screen.offset + 200  && scroll_position < range_third_screen.offset + range_third_screen.max  ? (
          <div>
            <Fade in={scroll_position > range_third_screen.offset + 200 } timeout={100} >
              <div className={classes.description_cont} style={{visibility:'visible'}}>
                <div style={{maxWidth:900}} className={classes.description_text}>
                  <Typography className={classes.text}  >
                    We are a company of professionals from different backgrounds like medicine,
                    human-centered design, engineering, innovation, hardware, software, digital,
                    business strategy, management, consulting, marketing, sales, luxury, arts and
                    research.
                  </Typography>
                </div>
                <div className={classes.rectangle_container} >
                  <img src={logo_white} className={classes.logo_white}  />          
                </div>   
              </div>
            </Fade>
          </div>
        ) : null } 
        {scroll_position > range_video_screen.offset - 50 && scroll_position < range_video_screen.offset + range_video_screen.max  ? (
          <div className={classes.video_container} style={{visibility:'visible'}}>
            {scroll_position < range_video_screen.offset + 1300 ? <div className={classes.overlay} >
              <LogoGeneralMask scale={overlay_scale}/>
            </div> : null }
            {scroll_position > range_video_screen.offset + 1370 ? <div className={classes.video} >
            <video width="100%" height={'100%'}  controls autoPlay style={{position:'relative', zIndex:10}}  >
                <source src={video} type="video/mp4" />
            </video></div> : null}  
          </div>
        ) : null }
        {scroll_position > range_video_screen.offset + range_video_screen.max + 50  ? (
          <div className={classes.imageContainer} style={{visibility:'visible', background:'white'}} > 
            <Zoom in={scroll_position > range_video_screen.offset + range_video_screen.max + 50 } timeout={3000} >
              <img src={image5} alt='' className={classes.image}/> 
            </Zoom>  
          </div>
        ) : null }
      </div> 
    </div>
  )
  
}



const styles = theme => ({
  generalContainer:{
    width:1400,
    margin:'auto',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px'
    },
  },
  olas:{
    position:'absolute',
    bottom:0,
    //zIndex:1
  },
  logo_white:{
    width:500
  },
  video:{
    position:'relative',
    height:'70vh',
    top:'20%',
    [theme.breakpoints.down('sm')]: {
      top:'50%',
      height:'100%',
      transform:'translate(0px, -50%)',
      //background:theme.palette.primary.main,
      //height:'100%'
    },
  },
  text:{
    fontSize:36,
    fontWeight:500,
    width:1400,
    [theme.breakpoints.only('lg')]: {
      fontSize:32,
      width:1100
    },
    [theme.breakpoints.down('md')]: {
      fontSize:28,
      width:'80%'
    },
    /* [theme.breakpoints.only('sm')]: {
      width:340,
      fontSize:26,
    }, */
    [theme.breakpoints.only('xs')]: {
      //marginLeft:60,
      width:'80%',
      fontSize:20,
      //background:'red'
    },
  },
  root:{
    height:`${totalHeight}px`,
    position:'relative',
    background:'black',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
  },
  rootB:{
    background:'black',
    color:'white',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
  },
  rootC:{
    background:'black',
    color:'white',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
  },
  emptySpace:{
    height:`calc(${totalHeight}px - 100vh)`,
    position:'absolute',
    top:0,
    left:'50%',
    width:'1px',
    visibility:'hidden',
    boxSizing:'border-boxSizing'
  },
  container:{
    width:'100%',
    height:'100vh',
    position:'sticky',
    top:'0px',
  },
  subcontainer:{
    
  },
  containerB:{
    background:'black',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 2500,
    }),
  },
  imageContainer:{
    position:'absolute',
    width:'100%',
    height:'100vh',
    //background:'transparent',
    overflow:'hidden',
    //background:'red'
  },
  image1:{
    width:'100%',
    height:'100%',
    background:'white',
    [theme.breakpoints.down('md')]: {
      objectFit:'contain',
      height:'100%'
    },
  },
  image:{
    width:'100%',
    //height:'100%',
    //background:'red',
    [theme.breakpoints.down('md')]: {
      objectFit:'contain',
      height:'100%'
    },
    /* [theme.breakpoints.only('sm')]: {
      height:'100%',
      width:'auto'
    },
    [theme.breakpoints.only('xs')]: {
      height:'100%',
      width:'auto'
    }, */
  },
  image3:{
    width:'100%',
    transform:'rotate(-15deg)',
    [theme.breakpoints.only('md')]: {
      height:'100%',
      marginTop:100,
      objectFit:'cover'
      //width:'auto'
    },
    [theme.breakpoints.down('sm')]: {
      height:'100%',
      objectFit:'cover'
      //width:'auto'
    },
  },
  image4:{
    width:'100%',
    transform:'rotate(10deg)',
    marginLeft:400,
    marginTop:100,
    [theme.breakpoints.only('md')]: {
      height:'100%',
      width:'auto',
      marginLeft:0,
      marginTop:150,
    },
    /* [theme.breakpoints.only('sm')]: {
      height:'100%',
      width:'auto',
      marginLeft:0,
      marginTop:100,
    }, */
    [theme.breakpoints.down('sm')]: {
      height:'100%',
      width:'100%',
      marginLeft:0,
      marginTop:0,
      objectFit:'contain',
      //transform:'rotate(0deg)',
    },
  },
  letter:{
    fontFamily:'Avenir',
    fontSize:140,
    fontWeight:600,
    padding:0,
    margin:0,
  },
  txtWrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-70%, -50%)',
    zIndex:3
  },
  textB_wrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%, -50%)',
    zIndex:3
  },
  textB_container:{ 
    width:1200,
    fontFamily:'Avenir',
    fontSize:120,
    fontWeight:600,
  },
  overlay:{
    //background:theme.palette.primary.main, 
    background:'white',
    width:'100%', 
    height:'100%',
    top:0,
    left:0,
    position:'absolute',
    zIndex:1
  },
  screenWrapperA:{
    overflowX:'hidden', 
    background:'black',
  },
  description_cont:{
    background:theme.palette.primary.main,
    //background:'transparent',
    position:'relative',
    //width:'100%',
    //width:'100vw',
    height:'100vh',
    padding:100,
    [theme.breakpoints.down('sm')]: {
      padding:32
    },
    //boxSizing:'border'
  },
  video_container:{
    position:'relative',
    height:'100vh',
    background:theme.palette.primary.main
    //background:'red'
  },
  description_text:{
    /* [theme.breakpoints.down('sm')]: {
      marginTop:200
    },
    [theme.breakpoints.down('xs')]: {
      marginTop:100
    }, */
  },
  rectangle_container:{
    /* width:'100%',
    position:'relative',
    display:'flex',
    justifyContent:'center',
    //alignItems:'center',
    marginTop:200,
    background:'red' */
    width:'100%',
    display:'flex',
    justifyContent:'center',
    [theme.breakpoints.only('xl')]: {
      marginTop:50
    },
    //height:500,
    //background:'red'
  },
  pink_rectangle:{
    background:theme.palette.primary.main,
    width:'70%',
    height:300,
    margin:'200px auto 0px',
    [theme.breakpoints.down('sm')]: {
      height:200
    },
    [theme.breakpoints.down('xs')]: {
      height:100,
      marginTop:100
    },
  }
  
})

export default withStyles(styles)(SectionB)