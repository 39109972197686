import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import image from '../../assets/ALTHAR_WEB_Physician-Journeys.png'

const Physician = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <div  >
                <Typography className={classes.title} variant='h2'>Physician</Typography>
                <Typography className={classes.title} variant='h2'>Journeys</Typography>
                
            </div>
            <div className={classes.challenge}>
                <Typography className={classes.subtitle} variant='subtitle1'>Challenge.</Typography>
                <Typography className={classes.text}>
                    Our client, a leading pharmaceutical company, needs to develop a launch strategy 
                    in a very complicated and crowded market for an innovative product where 
                    the targeted physicians are struggling to pick the best options for patients.
                </Typography>
            </div>
            <div className={classes.solution}>
                <Typography className={classes.subtitle} variant='subtitle1'>Solution.</Typography>
                <Typography className={classes.text}>
                    Âlthar developed a 
                    customized physician
                    methodology with surveys 
                    and building sessions in
                    order to know the thinking, 
                    feeling and doing of the
                    targeted physicians.
                </Typography>
            </div>
            <div className={classes.result}>
                <Typography className={classes.subtitle} variant='subtitle1'>Result.</Typography>
                <Typography className={classes.text}>
                    We found 4 key insights that change the
                    complete medical and marketing plan from global.
                    With a detailed level, and a year later our client
                    achieve the qualitative and quantitative goals.
                    The product is the number one prescription in
                    its category.
                </Typography>
            </div>
            {/* <div className={classes.img_container}>
                <img src={image} alt='' className={classes.img} />
            </div> */}
            <img src={image} alt='' className={classes.img} />
            <div className={classes.line_container} style={{top:'25%', left:'9%'}}>
                <div className={classes.line2} style={{width:50, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:340, marginTop:-16, marginLeft:-6}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'59%', left:'15%'}}>
                <div className={classes.line2} style={{width:30, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:500, marginTop:-10, marginLeft:-3}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'48%', right:'10%'}}>
                <div className={classes.line} style={{width:110, marginTop:-16, marginRight:-5}}></div> 
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>        
            </div>
        </div>
    )
}

export default Physician

const useStyles = makeStyles(theme => ({
    root:{
      background:theme.palette.primary.main,
      //background:'red',
      height:'100vh',
      padding:'5%',
      paddingTop:'3%',
      color:'white',
      position:'relative'
    },
    title:{
        lineHeight:0.9,
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
          },
    },
    subtitle:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.2rem'
          },
    },
    text:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.9rem'
          },
    },
    line_container:{
        position:'absolute',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
    },
    line:{
        height:0.5,
        background:'white',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        //width:180,
    },
    line2:{
        height:0.5,
        background:'white',
        width:60,
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        
    },
    challenge:{
        position:'absolute',
        top:'26%',
        //bottom:'50%',
        left:'5%',
        //paddingTop:'50%',
        //paddingTop:'5%',
        maxWidth:360,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'green',
            top:'auto',
            left:0,
            marginTop:12,
            maxWidth:240
            
          },
        [theme.breakpoints.down('xs')]:{
            maxWidth:'100%'
        },
        
    },
    solution:{
        position:'absolute',
        top:'50%',
        right:'5%',
        maxWidth:260,
        textAlign:'right',
        //background:'black'
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'blue',
            top:0,
            right:0,
            left:0,
            textAlign:'left',
            margin:'12px 0px'
        },
        [theme.breakpoints.down('xs')]:{
            maxWidth:'100%'
        },
        
    },
    result:{
        position:'absolute',
        //top:'63%',
        top:'60%',
        //right:'30%',
        left:'5%',
        maxWidth:600,
        [theme.breakpoints.down('md')]:{
            //left:'5%'
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:'auto',
            left:0, 
        },
        
    },
    img_container:{
        position:'absolute',

        top:-10,
        bottom:10,
        height:100,
        //left:'50%',
        background:'black'
    },
    img:{
        //width:'100%',
        //height:'100%',
        //transform:'rotate(10deg)',
        objectFit:'cover',
        position:'absolute',
        top:'-15%',
        left:'32%',
        //right:'15%',
        //right:'20%',
        //background:'black',
        width:'50%',
        [theme.breakpoints.down('md')]:{
            //transform:'scale(0.8) rotate(25deg)',
            //right:'10%',
            //top:-20
            left:'35%'
        },
        [theme.breakpoints.down('sm')]:{
            //transform:'scale(0.8) rotate(25deg)',
            left:'40%',
            top:'18%'
        },
        [theme.breakpoints.down('xs')]:{
            display:'none'
        }

        //objectPosition:'center'
        //bottom:20
    }
    
  }))