import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import manifiestA from '../../../../../assets/manifiestoA.png'
import manifiestA from '../../../../../assets/carouselC.jpg'


const Manifiesto = ({classes}) => {

    return(
      <div className={classes.root}>
        <img src={manifiestA} alt='' className={classes.image}/>
      </div>
    )
  
}

const styles = theme => ({
  root:{
    width:'100%',
    height:'100vh',
    background:'black',
    color:'white'
  },
  image:{
    /* position:'absolute',
    top:'50%',
    right:'50%',
    transform:'translate(50%,-50%)',
    width:1000 */
    width:'100%',
    height:'100%',
    objectFit:'cover'
    
  },
  cardsSlider:{
    position:'relative',
    width:'100%'
  },
  cardsSliderWrapper:{
    display:'flex',
    flexWrap:'nowrap',
  },
  card:{
    flex:1,
  }
})

export default withStyles(styles)(Manifiesto)