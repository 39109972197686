import React from 'react'
import { makeStyles, Fade } from '@material-ui/core';
import logo from '../../../../../assets/new_logos/ALTHAR_LOGO_VERDE_ICONO-07.svg'

const useStyles = makeStyles(theme => ({
  svgImage:{
    position:'absolute',
    //height:'642px',
    //width:'1332px',
    top:'50%',
    left:'50%',
    transform:'translate(-50%, -50%) scale(1)',
    zIndex:2,
    [theme.breakpoints.only('lg')]: {
      transform:'translate(-50%, -50%) scale(0.85)',
    },
    [theme.breakpoints.only('md')]: {
      transform:'translate(-50%, -50%) scale(0.7)',
    },
    [theme.breakpoints.only('sm')]: {
      transform:'translate(-50%, -50%) scale(0.5)',
    },
    [theme.breakpoints.only('xs')]: {
      transform:'translate(-50%, -50%) scale(0.4)',
    },
  },
}))

const IconMask = props => {

  const {visibility} = props
  const classes = useStyles()

  return(
    <div className={classes.svgImage} 
      style={{
        visibility:visibility ? 'visible' : 'hidden'
      }}
      >
          <Fade in timeout={0}>
            <div>
              {/* <SVG /> */}
              <img src={logo} alt='' height={700}  />
            </div>
          </Fade>
      
    </div>
  )
}

export default IconMask


const SVG = ({
  className="" 
}) => {
  
  return(
  <svg  x="0px" y="0px"
  width="100%" height="100%" className={`svg-icon ${className || ""}`}
	>
      {/* <polygon fill="#C83D84" points="983.75,12.242 350.791,12.242 12.104,472.203 12.104,629.551 354.839,629.551 669.294,250.319 
      975.653,629.551 1322.376,629.551 1322.376,472.203 "/>
      <polygon fill="#D64189" points="482.092,12.235 983.746,12.235 1322.372,472.205 1322.372,629.552 977.361,629.552 "/> */}
      <polygon fill="#00ADB5" points="250 180, 1000 180, 1000 500, 250 500" /> 
  </svg>
  )
}