import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import image from '../../assets/ALTHAR_WEB_iRWE.png'

const RWE = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <div className={classes.title_container}>
                <Typography className={classes.title} variant='h2'>RWE</Typography>
            </div>   
            <div className={classes.challenge}>
                <Typography className={classes.subtitle} variant='subtitle1'>Challenge.</Typography>
                <Typography className={classes.text}>
                    A huge and common lack in 
                    Latin America is
                    data. There are no reliable 
                    data of any disease. Our 
                    client wants to approach a 
                    healthcare system in a 
                    country in order to help the 
                    main number of patients to 
                    receive treatment.
                </Typography>
            </div>
            <div className={classes.solution}>
                <Typography className={classes.subtitle} variant='subtitle1'>Solution.</Typography>
                <Typography className={classes.text}>
                    We designed and developed a 
                    Real World Evidence (RWE) 
                    digital platform based on EMR 
                    and a methodology process to 
                    find, analize and present 
                    aggregated data for the 
                    condition and treatments.
                </Typography>
            </div>
            <div className={classes.result}>
                <Typography className={classes.subtitle} variant='subtitle1'>Result.</Typography>
                <Typography className={classes.text}>
                    The government accepted the RWE 
                    platform as an add-value solution from 
                    our client, and for the first time. They 
                    have reliable data to publish and take 
                    pharmacoeconomic decisions.
                    The final beneficiary: The patients.
                </Typography>
            </div>
            {/* <div className={classes.img_container}>
                <img src={image} alt='' className={classes.img} />
            </div> */}
            <img src={image} alt='' className={classes.img} />
            <div className={classes.line_container} style={{top:'49%', left:'16%'}}>
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>
                <div className={classes.line} style={{width:160, marginTop:16, marginLeft:-7}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'56%', left:'8%'}}>
                <div className={classes.line2} style={{width:30, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:320, marginTop:-10, marginLeft:-3}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'60%', right:'12%'}}>
                <div className={classes.line} style={{width:350, marginTop:-16, marginRight:-5}}></div> 
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>        
            </div>
        </div>
    )
}

export default RWE

const useStyles = makeStyles(theme => ({
    root:{
      background:theme.palette.secondary.main,
      //background:'red',
      height:'100vh',
      padding:'5% 5% 0px',
      color:'white',
      position:'relative'
    },
    title_container:{
        position:'absolute',
        right:'5%',
        top:'10%',
        textAlign:'right',
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            right:'auto',
            top:'auto',
            marginBottom:12,
            marginTop:50,
            textAlign:'left'
        },
    },
    title:{
        lineHeight:0.9,
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
          },
    },
    subtitle:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.2rem'
          },
    },
    text:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.9rem'
          },
    },
    line_container:{
        position:'absolute',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
    },
    line:{
        height:0.5,
        background:'white',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        //width:180,
    },
    line2:{
        height:0.5,
        background:'white',
        width:60,
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        
    },
    challenge:{
        position:'absolute',
        //top:'35%',
        top:'10%',
        left:'5%',
        //paddingTop:'50%',
        //paddingTop:'5%',
        maxWidth:300,
        [theme.breakpoints.down('md')]:{
            maxWidth:200
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'green',
            top:0,
            left:0,
            marginTop:12
            
          },
        [theme.breakpoints.down('xs')]:{
            maxWidth:'100%'
          },
        
    },
    solution:{
        position:'absolute',
        top:'62%',
        right:'5%',
        maxWidth:400,
        textAlign:'right',
        //background:'black'
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'blue',
            top:'auto',
            right:0,
            left:0,
            textAlign:'left',
            margin:'12px 0px'
        },
        
    },
    result:{
        position:'absolute',
        //top:'63%',
        top:'56%',
        left:'5%',
        maxWidth:340,
        [theme.breakpoints.down('md')]:{
            top:'58%'
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:'auto',
            left:0, 
        },
        
    },
    img_container:{
        position:'absolute',

        top:-10,
        bottom:10,
        height:100,
        //left:'50%',
        background:'black'
    },
    img:{
        //width:'100%',
        //height:'100%',
        //transform:'rotate(25deg)',
        objectFit:'cover',
        position:'absolute',
        top:'-15%',
        left:'28%',
        //background:'black',
        width:'55%',
        [theme.breakpoints.down('md')]:{
           top:0
        },
        [theme.breakpoints.down('sm')]:{
            
            left:'40%',
            top:'10%',
            width:'40%'
        },
        [theme.breakpoints.down('xs')]:{
            display:'none'
        }

        //objectPosition:'center'
        //bottom:20
    }
    
  }))