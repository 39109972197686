import React from 'react'
import {Grid, makeStyles, Slide, Typography } from '@material-ui/core'
import useUsView from './useContactView';
import logo from '../../assets/new_logos/ALTHAR_LOGO_VERDE_TEXTO-02-02.svg'

const ContactView = ({language}) =>{

  const classes = useStyles()
  const content = contentData[language]
  const {scroll_position, actions, modals} = useUsView({})

  const items = Object.values(content)
  //console.log(items)

  return(
    <div className={classes.root}>  
      <Slide direction='left' in={true}  timeout={1200}>
        <div className={classes.logo_container}>
            <img src={logo} alt='' className={classes.logo}/>
        </div>
      </Slide> 
      <div className={classes.info_container}>   
        <a className={classes.link} target='_blank' href='info@althar.com' >info@althar.com</a>
        <div style={{color:'white', marginTop:40}}>
          <Typography  >Palenque 369, Colonia Narvarte, Benito Juárez</Typography>
          <Typography >Ciudad de México, CP 03400</Typography>
          <Typography>+52 55 5335 1351</Typography>
        </div>
      </div>
    </div>
  )

}


const useStyles = makeStyles(theme => ({
  root:{
    background:'black',
    //padding:'200px 0px 100px',
    paddingTop:200,
    height:'100vh'
  },
  link:{
    position:'relative',
    //zIndex:20,
    textDecoration:'none',
    color:theme.palette.primary.main,
    fontSize:'1.2rem',
  },
  logo_container:{
    display:'flex',
    justifyContent:'center',
    width:'100%',
    //margin:'200px 0px'
  },
  info_container:{
    paddingLeft:200,
    marginTop:100,
    color:theme.palette.primary.main,
    //width:'100%',
    [theme.breakpoints.down('sm')]: {
      padding:'0px 32px',
      marginTop:100
    },
  },
  logo:{
    width:200,
  }
}))

const contentData = {
  english:{
    sectionA:{
      title:'Mission',
      data:'We are transforming health with technology, data, design and frenzy for life.',
      timeout:2400
    },
    sectionB:{
      title:'Courage',
      data:'Enthusiasm, true conviction and fearless believe lead our standards way beyond expectations.',
      timeout:3000
    },
    sectionC:{
      title:'Character',
      data:'Mixing our talents and moral quality; We have the right motives to achieve greater results.',
      timeout:3600
    },
    sectionD:{
      title:'Integrity',
      data:'We always do the right things, building up strong and lasting working relationships.',
      timeout:4200
    },
    sectionE:{
      title:'Innovation',
      data:'We strive toward to create and design.',
      timeout:4800
    }
  }
}

export default ContactView