import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import image from '../../../../../../assets/PNG/PNG/WEBnegro_22.png'

const Gluck = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <Typography className={classes.title} variant='h2'>gluck!</Typography>
            <div className={classes.challenge}>
                <Typography className={classes.subtitle} variant='subtitle1'>Challenge.</Typography>
                <Typography className={classes.text}>
                    Design a glucometer easy to use, easy to carry, and with an 
                    engaging appearance for the patients, in order to increase
                    the self-monitoring, without social restrictions.
                </Typography>
            </div>
            <div className={classes.solution}>
                <Typography className={classes.subtitle} variant='subtitle1'>Solution.</Typography>
                <Typography className={classes.text}>
                    gluck!, the first glucometer with a real user-friendly,
                    experience, screenless, buttonless, with Al-based hardware
                    development, and with an exterior design that provokes
                    showing off.
                </Typography>
            </div>
            <div className={classes.result}>
                <Typography className={classes.subtitle} variant='subtitle1'>Result.</Typography>
                <Typography className={classes.text} >
                    gluck! has proof 57% more adherence in self-monitoring rates
                    with type 2 diabetes patients. Patients feel supported by Al advice. 
                    Also, patients manifest that they feel gluck! as a fashion device
                    that doesn't generate social rejection; on the contrary, people 
                    around love gluck!
                </Typography>
            </div>
            {/* <div className={classes.img_container}>
                <img src={image} alt='' className={classes.img} />
            </div> */}
            <img src={image} alt='' className={classes.img} />
            <div className={classes.line_container} style={{top:'50%', left:'18%'}}>
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>
                <div className={classes.line} style={{width:240, marginTop:16, marginLeft:-7}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'56%', left:'16%'}}>
                <div className={classes.line2} style={{width:30, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:340, marginTop:-10, marginLeft:-3}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'40%', right:'12%'}}>
                <div className={classes.line} style={{width:340, marginTop:-16, marginRight:-5}}></div> 
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>        
            </div>
        </div>
    )
}

export default Gluck

const useStyles = makeStyles(theme => ({
    root:{
      background:theme.palette.primary.main,
      //background:'red',
      height:'100vh',
      padding:'5% 5% 0px',
      color:'white',
      position:'relative',
      [theme.breakpoints.down('sm')]:{
        //height:'',
        padding:'20px',
        //background:'red',
        overflowY:'scroll'
      },
    },
    title:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
          },
    },
    subtitle:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.2rem'
          },
    },
    text:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.9rem'
          },
    },
    challenge:{
        position:'absolute',
        //top:'35%',
        bottom:'50%',
        left:'5%',
        //paddingTop:'50%',
        //paddingTop:'5%',
        maxWidth:340,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'green',
            bottom:'auto',
            left:0,
            margin:'12px 0px'
          },
        
    },
    line_container:{
        position:'absolute',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
    },
    line:{
        height:0.5,
        background:'white',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        //width:180,
    },
    line2:{
        height:0.5,
        background:'white',
        width:60,
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        
    },
    solution:{
        position:'absolute',
        top:'45%',
        right:'5%',
        maxWidth:280,
        textAlign:'right',
        //background:'black'
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'blue',
            top:'auto',
            right:0,
            left:0,
            textAlign:'left',
            
            //maxWidth:'auto'
            //left:'10%'
            //right:'auto'
          },
        
    },
    result:{
        position:'absolute',
        //top:'63%',
        bottom:'12%',
        left:'10%',
        maxWidth:390,
        [theme.breakpoints.down('md')]:{
            left:'5%'
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            bottom:'auto',
            left:0,
            margin:'12px 0px'
          },
        
    },
    img_container:{
        position:'absolute',

        top:-10,
        bottom:10,
        height:100,
        //left:'50%',
        background:'black'
    },
    img:{
        //width:'100%',
        //height:'100%',
        transform:'rotate(25deg)',
        objectFit:'cover',
        position:'absolute',
        top:-12,
        right:'15%',
        //background:'black',
        width:'80vw',
        [theme.breakpoints.down('md')]:{
            transform:'scale(0.8) rotate(25deg)',
            //right:'10%',
            top:-20
        },
        [theme.breakpoints.down('sm')]:{
            transform:'scale(0.8) rotate(25deg)',
            right:'5%',
            top:'-8%'
        },
        [theme.breakpoints.down('xs')]:{
            display:'none'
          },

        //objectPosition:'center'
        //bottom:20
    }
    
  }))