import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import image from '../../assets/Althar_02.png'

const Digital = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <div className={classes.title_container} >
                <Typography className={classes.title} variant='h2'>Digital Therapeutics</Typography>
            </div>
            <div className={classes.challenge}>
                <Typography className={classes.subtitle} variant='subtitle1'>Challenge.</Typography>
                <Typography className={classes.text}>
                    Help our client to increase the self-monitoring practice in
                    pediatric patients with type 1 diabetes.
                </Typography>
            </div>
            <div className={classes.solution}>
                <Typography className={classes.subtitle} variant='subtitle1'>Solution.</Typography>
                <Typography className={classes.text}>
                    The Althar technology team designed an evidence-based
                    therapeutic intervention driven by high-quality 
                    software and a simple pediatric-focus UX, to increase
                    self-monitoring and prevent complications, as well as teach
                    to little patients how to manage their condition.
                </Typography>
            </div>
            <div className={classes.result}>
                <Typography className={classes.subtitle} variant='subtitle1'>Result.</Typography>
                <Typography className={classes.text}>
                    An improvement of 47% in adherence to self-monitoring practice.
                </Typography>
            </div>
            {/* <div className={classes.img_container}>
                <img src={image} alt='' className={classes.img} />
            </div> */}
            <img src={image} alt='' className={classes.img} />
            <div className={classes.line_container} style={{top:'24%', right:'48%'}}>
                <div className={classes.line} style={{width:400, marginTop:-9, marginRight:-4}}></div> 
                <div className={classes.line2} style={{width:25, transform:'rotate(45deg)'}}></div>        
            </div>
            <div className={classes.line_container} style={{top:'49%', right:'14%'}}>
                <div className={classes.line} style={{width:800, marginTop:-9, marginRight:-4}}></div> 
                <div className={classes.line2} style={{width:25, transform:'rotate(45deg)'}}></div>        
            </div>        
            <div className={classes.line_container2} >
                <div className={classes.line} style={{width:400, marginTop:-9, marginRight:-4}}></div> 
                <div className={classes.line2} style={{width:25, transform:'rotate(45deg)'}}></div>        
            </div>
        </div>
    )
}

export default Digital

const useStyles = makeStyles(theme => ({
    root:{
      background:theme.palette.secondary.main,
      //background:'red',
      height:'100vh',
      //padding:'5% 5% 0px',
      color:'white',
      position:'relative',
      [theme.breakpoints.down('sm')]:{
        //height:'',
        padding:'20px',
        //background:'red',
        overflowY:'scroll'
      },
    },
    title_container:{
        position:'absolute',
        right:'5%',
        top:'10%',
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            right:'auto',
            top:'auto',
            marginBottom:12
        },
    },
    title:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
          },
    },
    subtitle:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.2rem'
          },
    },
    text:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.9rem'
          },
    },
    line_container:{
        position:'absolute',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
    },
    line_container2:{
        position:'absolute',
        display:'flex',
        top:'73%', right:'55%',
        [theme.breakpoints.only('xl')]:{
            top:'73%', right:'50%',
          },
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
    },
    line:{
        height:0.5,
        background:'white',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        //width:180,
    },
    line2:{
        height:0.5,
        background:'white',
        width:60,
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        
    },
    challenge:{
        position:'absolute',
        top:'25%',
        //bottom:'50%',
        left:'50%',
        //paddingTop:'50%',
        //paddingTop:'5%',
        maxWidth:340,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'green',
            top:'auto',
            left:0,
            
          },
        
    },
    solution:{
        position:'absolute',
        top:'50%',
        right:'5%',
        maxWidth:280,
        textAlign:'right',
        //background:'black'
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'blue',
            top:'auto',
            right:0,
            left:0,
            textAlign:'left',
            margin:'12px 0px'
          },
        
    },
    result:{
        position:'absolute',
        //top:'63%',
        top:'75%',
        right:'30%',
        maxWidth:390,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:'auto',
            left:0,
            right:0
            
          },
        
    },
    img_container:{
        position:'absolute',

        top:-10,
        bottom:10,
        height:100,
        //left:'50%',
        background:'black'
    },
    img:{
        //width:'100%',
        //height:'100%',
        //transform:'scaleX(-1)',
        objectFit:'cover',
        position:'absolute',
        top:'-50%',
        //right:'15%',
        left:'-10%',
        //background:'black',
        width:'60%',
        [theme.breakpoints.down('lg')]:{
            //transform:'scale(0.8) rotate(25deg)',
            //right:'10%',
            top:'-50%',
            left:'-25%'
        },
        [theme.breakpoints.down('md')]:{
            //transform:'scale(0.8) rotate(25deg)',
            //right:'10%',
            //top:'-30%'
        },
        [theme.breakpoints.down('sm')]:{
            //transform:'scale(0.8) rotate(25deg)',
            //right:'5%',
            top:0,
            left:'50%'
        },
        [theme.breakpoints.down('xs')]:{
            display:'none'
        },

        //objectPosition:'center'
        //bottom:20
    }
    
  }))