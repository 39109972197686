import React from 'react'
import { makeStyles, Grid, Fade } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root:{
    position:'absolute',
    zIndex:1,
    width:'100%',
    height:'100vh',
  },
  container:{
    width:'100%',
    //paddingRight:40,
    //margin:'auto',
    color:'white',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    padding:'0px 70px',
    [theme.breakpoints.only('lg')]: {
      //width:1100,
      //background:'green'
      padding:'0px 70px'
    },
    [theme.breakpoints.down('md')]: {
      width:'100%',
      //background:'green',
      padding:'0px 32px'
    },
    /* [theme.breakpoints.down('sm')]: {
      width:'100%',
      //padding:'0px 50px'
    }, */
  },
  subcontainer:{
    position:'absolute',
    top:'10%',
    //transform:'translateY(-50%)'
  },
  title:{
    fontSize:160,
    fontWeight:700,
  },
  subtitle:{
    fontSize:40,
    fontWeight:500,
    width:1400,
    [theme.breakpoints.only('lg')]: {
      fontSize:40,
      width:1100
    },
    [theme.breakpoints.down('md')]: {
      fontSize:32,
      width:'80%'
    },
    /* [theme.breakpoints.only('sm')]: {
      width:340,
      fontSize:26,
    }, */
    [theme.breakpoints.only('xs')]: {
      //marginLeft:60,
      marginTop:40,
      width:'80%',
      fontSize:20,
    },
  }
}))

const ScreenD = props => {

  const classes = useStyles()
  const {scroll, range, fade_in} = props

  return(
    <div className={classes.root}>
      <div className={classes.container}>
        
          <div className={classes.subcontainer}>
            <Fade in={scroll >= (range.min + range.offset)} timeout={1000}>
              <div>
                <Grid container>
                  <Grid item xs={12}  >
                    <div className={classes.subtitle} 
                    style={{color:fade('#FFF', fade_in)}}
                    >
                      We partner with our clients: startups, Fortune 500
                      companies and public sector organizations, to solve complex
                      problems through human-centered design.
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Fade>
          </div>
        
      </div>
    </div>
  )
}

export default ScreenD