import React, { useEffect } from 'react'
import {Button, Grid, Icon, makeStyles, Slide, Typography, Zoom } from '@material-ui/core'
import logo from '../../assets/new_logos/ALTHAR_LOGO_CLARO_TEXTO-05.svg'
import prueba from '../../assets/punch.png'
import news from '../../assets/PNG/PNG/news_71-03.svg'

const NewslettersView = ({language}) =>{

  const classes = useStyles()
  
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return(
    <div className={classes.root}>  
      <Slide direction='right' in={true}  timeout={1200}>
        <div className={classes.title_container}>
            <img src={logo} alt='' className={classes.logo}/>
            <Typography  className={classes.title} >NEWSLETTERS</Typography>
        </div>
      </Slide> 
      <div className={classes.values_container}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className={classes.image_container}>
              <img alt='' src={news} />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Slide in timeout={3000} direction='up'>
              <div className={classes.container}>
                <div>
                  <Typography variant='h4' >Subscribe</Typography>
                  <Button className={classes.button} startIcon={<Icon>email</Icon>} >Your email</Button>
                </div>
              </div>
            </Slide>
          </Grid>
        </Grid>
      </div>
    </div>
  )

}


const useStyles = makeStyles(theme => ({
  root:{
    position:'relative',
    padding:'50px 40px',
    background:theme.palette.secondary.main,
    height:'100vh',
    overflowY:'hidden',
    [theme.breakpoints.down('sm')]: {
      padding:'100px 20px'
    },
  },
  title:{
    //color:theme.palette.primary.main,
    fontSize:'4rem',
    fontWeight:600,
    marginLeft:0,
    marginTop:52,
    [theme.breakpoints.down('sm')]: {
      marginLeft:20,
      marginTop:-24,
      fontSize:'3rem'
    },
  },
  title_container:{
    position:'relative',
    display:'flex',
    alignItems:'center',
    [theme.breakpoints.down('sm')]: {
      display:'inline'
    },
  },
  values_container:{
    //marginLeft:40,
    marginTop:50,
  },
  logo:{
    width:400,
    paddingBottom:12,
    [theme.breakpoints.down('sm')]: {
      width:300
    },
  },
  image_container:{
    width:600,
    margin:'auto',
    [theme.breakpoints.down('sm')]: {
      width:300,
      marginBottom:40
    },
  },
  image:{
    width:'100%',
    height:'100%',
    objectFit:'contain'
  },
  button:{
    //background:theme.palette.primary.main,
    color:'white',
    fontSize:'1.2rem',
    padding:'16px 16px',
    textTransform:'none',
    '&:hover':{
      background:theme.palette.primary.dark,
    }
  },
  container:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    marginTop:160,
    [theme.breakpoints.down('sm')]: {
      marginTop:0
    },
  }
}))

const contentData = {
  english:{
    sectionA:{
      title:'Mission',
      data:'We are transforming health with technology, data, design and frenzy for life.',
      timeout:2400
    },
    sectionB:{
      title:'Courage',
      data:'Enthusiasm, true conviction and fearless believe lead our standards way beyond expectations.',
      timeout:3000
    },
    sectionC:{
      title:'Character',
      data:'Mixing our talents and moral quality; We have the right motives to achieve greater results.',
      timeout:3600
    },
    sectionD:{
      title:'Integrity',
      data:'We always do the right things, building up strong and lasting working relationships.',
      timeout:4200
    },
    sectionE:{
      title:'Innovation',
      data:'We strive toward to create and design.',
      timeout:4800
    }
  }
}

export default NewslettersView