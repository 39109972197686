import React from 'react'
import { makeStyles, Grid, Slide } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { palette } from '../../../../../variables/config';


const useStyles = makeStyles(theme => ({
  root:{
    position:'absolute',
    zIndex:1,
    width:'100%',
    height:'100vh',
  },
  container:{
    width:1400,
    margin:'auto',
    color:'white',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('xl')]: {
      width:1400,
    },
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px'
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 50px'
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      padding:'0px 30px'
    },
  },
  subcontainer:{
    position:'absolute',
    marginTop:100,
    [theme.breakpoints.only('sm')]: {
      position:'initial'
    },
    [theme.breakpoints.only('xs')]: {
      position:'initial'
    },
  },
  subcontainer2:{
    position:'absolute',
    bottom:0,
    marginBottom:140,
    [theme.breakpoints.down('sm')]: {
      bottom:'20%'
    },
  },
  title:{
    fontSize:72,
    fontWeight:700,
    width:800,
    [theme.breakpoints.only('lg')]: {
      width:600,
      fontSize:52,
    },
    [theme.breakpoints.only('md')]: {
      width:500,
      fontSize:48,
    },
    [theme.breakpoints.only('sm')]: {
      width:400,
      fontSize:48,
    },
    [theme.breakpoints.only('xs')]: {
      width:280,
      fontSize:32,
    },
  },
  subtitle:{
    marginTop:100,
    fontSize:48,
    fontWeight:500,
    [theme.breakpoints.only('lg')]: {
      fontSize:36,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:28,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize:32,
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: 80,
      fontSize:25,
    },
  }
}))

const ScreenB = props => {

  const classes = useStyles()
  const {scroll, range, fade_in, language, offset} = props

  const content = contentData[language]

  return(
    <div className={classes.root}>
      <div className={classes.container}>
        
          <div className={classes.subcontainer}>
            <Slide direction='right' in={scroll > range.min + range.offset + offset} timeout={1200}>
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.title} style={{color:fade('#FFF', fade_in)}}>
                      {content.title[0]} 
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.title} style={{color:fade(palette.primary.main, fade_in)}}>
                      {content.title[1]} 
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Slide>
          </div>
          <div className={classes.subcontainer2}>
            <Slide direction='right' in={scroll > range.min + range.offset + offset} timeout={1200}>
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.subtitle} style={{color:fade('#FFF', fade_in)}}>
                      {content.data} 
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Slide>
          </div>
        
      </div>
    </div>
  )
}

export default ScreenB

const contentData = {
  english:{
    title:['We design solutions that truly make a', 'difference.'],
    data:'Just a sample of our work'
  }
}