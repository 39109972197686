import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import image from '../../assets/ALTHAR_WEB_Experiential-Event-Stand-Alone.png'

const Experiential = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <div  >
                <Typography className={classes.title} variant='h2'>Experiential</Typography>
                <Typography className={classes.title} variant='h2'>Event-Stand Alone</Typography>
            </div>
            <div className={classes.challenge}>
                <Typography className={classes.subtitle} variant='subtitle1'>Challenge.</Typography>
                <Typography className={classes.text}>
                    The pharmeceutical industry is one of the more regulated 
                    worldwide. Because of this is really hard to be creative
                    and disruptive in medical meetings and CME programs.
                </Typography>
            </div>
            <div className={classes.solution}>
                <Typography className={classes.subtitle} variant='subtitle1'>Solution.</Typography>
                <Typography className={classes.text}>
                    Althar created a cognitive, experiential-based, 
                    interactive and technology-focused methodology
                    and structure to launch products and develop
                    summits.
                </Typography>
            </div>
            <div className={classes.result}>
                <Typography className={classes.subtitle} variant='subtitle1'>Result.</Typography>
                <Typography className={classes.text}>
                    In 2019, nearly more than 5k physicians evaluated our
                    events with NPS 5. We obtain more than 94% positive reviews
                    and recognition among our clients, and we have created more 
                    than 14k follow-up communications initiated by the attendees to 
                    our events.
                </Typography>
            </div>
            {/* <div className={classes.img_container}>
                <img src={image} alt='' className={classes.img} />
            </div> */}
            <div className={classes.line_container} style={{top:'25%', left:'9%'}}>
                <div className={classes.line2} style={{width:50, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:700, marginTop:-16, marginLeft:-6}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'51%', left:'8%'}}>
                <div className={classes.line2} style={{width:30, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:400, marginTop:-10, marginLeft:-3}}></div>     
            </div>
            <img src={image} alt='' className={classes.img} />
        </div>
    )
}

export default Experiential

const useStyles = makeStyles(theme => ({
    root:{
      background:theme.palette.primary.main,
      //background:'red',
      height:'100vh',
      padding:'5%',
      paddingTop:'2%',
      color:'white',
      position:'relative'
    },
    title:{
        lineHeight:0.9,
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
          },
    },
    subtitle:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.2rem'
          },
    },
    text:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.9rem'
          },
    },
    line_container:{
        position:'absolute',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
    },
    line:{
        height:0.5,
        background:'white',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        //width:180,
    },
    line2:{
        height:0.5,
        background:'white',
        width:60,
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        
    },
    challenge:{
        position:'absolute',
        top:'26%',
        //bottom:'50%',
        left:'5%',
        //paddingTop:'50%',
        //paddingTop:'5%',
        maxWidth:400,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'green',
            top:'auto',
            left:0,
            marginTop:12,
            maxWidth:300
            
          },
        
    },
    solution:{
        position:'absolute',
        top:'25%',
        right:'5%',
        maxWidth:220,
        textAlign:'right',
        //background:'black'
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'blue',
            top:'auto',
            right:0,
            left:0,
            textAlign:'left',
            margin:'12px 0px'
        },
    },
    result:{
        position:'absolute',
        //top:'63%',
        top:'51%',
        //right:'30%',
        left:'5%',
        maxWidth:400,
        [theme.breakpoints.down('md')]:{
            //left:'5%'
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:'auto',
            left:0, 
            maxWidth:300
        },
        
    },
    img_container:{
        position:'absolute',

        top:-10,
        bottom:10,
        height:100,
        //left:'50%',
        background:'black'
    },
    img:{
        //width:'100%',
        //height:'100%',
        //transform:'rotate(10deg)',
        objectFit:'cover',
        position:'absolute',
        top:'30%',
        right:'16%',
        //right:'15%',
        //right:'20%',
        //background:'black',
        width:'50%',
        [theme.breakpoints.only('md')]:{
            //transform:'scale(0.8) rotate(25deg)',
            //right:'10%',
            //top:-20
            width:'40%',
            right:'20%',
            display:'none'
        },
        [theme.breakpoints.down('sm')]:{
            //transform:'scale(0.8) rotate(25deg)',
            right:'5%',
            top:'30%'
        },
        [theme.breakpoints.down('xs')]:{
            display:'none'
        }

        //objectPosition:'center'
        //bottom:20
    }
    
  }))