import { Button, Card, Dialog, Grid, Input, makeStyles, Modal, TextField, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';


const BlockPage = ({open,onClose}) => {

    const classes = useStyles()

    const [value, setValue] = useState('')

    const onSubmit = () => {
        if(value === 'CORPAlthar2022.1'){
            localStorage.setItem('enablePlatform', 'active')
            localStorage.setItem('credentials', value)
            setValue('')
            onClose()
        }else{
            setValue('')
        }
    }

    if(!open) return null


    return ( 
        <Dialog open={open} fullScreen fullWidth>
            <div className={classes.root}>
                <div className={classes.container}>
                    <Typography align='center' variant='h3' style={{color:'white', marginBottom:32}}>
                        Bienvenido a Althar
                    </Typography>
                    <Typography align='center' variant='h5' style={{color:'white'}}>
                        Por favor ingresa el código de acceso
                    </Typography>
                    <Card className={classes.form}>
                        <TextField value={value} label='Código de acceso' fullWidth variant='outlined' onChange={(e)=> setValue(e.currentTarget.value)}/>
                    </Card>
                    <div>
                        <Grid container justify='center'>
                            <Grid item>
                                <Button className={classes.button} onClick={onSubmit} >ACCEDER</Button>
                            </Grid>
                        </Grid>
                    </div>

                </div>
            </div>
        </Dialog>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        width:'100%',
        height:'100vh',
        background:theme.palette.primary.main
    },
    container:{
        padding:40
    },
    form:{
        width:'100%',
        maxWidth:600,
        padding:16,
        margin:'40px auto'
    },
    button:{
        background:theme.palette.secondary.main,
        color:'white',
        fontWeight:500,
        fontSize:'1.250rem',
        padding:'12px 24px',
        '&:hover':{
            background:theme.palette.secondary.dark,
        }
    }
}))
 
export default BlockPage;