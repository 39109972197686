import React from 'react'
import { makeStyles, withWidth, Fade, Grid } from '@material-ui/core';
import logo from '../../../../../assets/new_logos/ALTHAR_LOGO_VERDE_TEXTO-02-02.svg'
import { grey } from '@material-ui/core/colors';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
  root:{
    position:'absolute',
    //position:'relative',
    //zIndex:99,
    width:'100%',
    height:'100vh',
    //background:'red'
  },
  container:{
    //width:1400,
    margin:'100px auto',
    paddingLeft:50,
    //padding:100,
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    //background:'red',
    //width:300,
    [theme.breakpoints.only('lg')]: {
      //width:1100,
      //margin:'100px auto',
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px',
      margin:'80px auto',
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 32px',
      marginTop:180,
      height:230,
      zIndex:200,
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      padding:'0px 32px',
      marginTop:180,
      //height:260,
      //zIndex:200
      //background:'red',
    },
  },
  title:{
    fontSize:72,
    fontWeight:700,
    marginBottom:40,
    [theme.breakpoints.only('lg')]: {
      fontSize:62,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:42,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize:38,
      
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom:22,
      marginTop:-24,
      fontSize:32,
      
    },
  },
  subtitle:{
    marginTop:32,
    fontSize:28,
    fontWeight:400,
    width:500,
    //color: grey[700],
    //background:'red',
    [theme.breakpoints.only('lg')]: {
      width:400,
      fontSize:24,
    },
    [theme.breakpoints.only('md')]: {
      width:500,
      fontSize:22,
    },
    [theme.breakpoints.down('sm')]: {
      width:'100%',
      //marginTop:15,
      fontSize:18,
      //background:'red'
    },
    [theme.breakpoints.only('xs')]: {
   
      fontSize:16,
      //background:'red'
    },
  },
  logo:{
    width:420,
    //marginTop:100,
    //background:'red',
    [theme.breakpoints.only('lg')]: {
      width:380,
    },
    [theme.breakpoints.only('md')]: {
      width:340,
    },
    [theme.breakpoints.only('sm')]: {
      width:300,
      marginLeft:-16
    },
    [theme.breakpoints.only('xs')]: {
      width:220,
      marginLeft:-16
    },
  },
  header:{
    //background:'red',
    marginLeft:-34,
    [theme.breakpoints.only('sm')]: {
      marginTop:-120,
      marginLeft:0,
    },
    [theme.breakpoints.only('xs')]: {
      marginTop:-130,
      marginLeft:0,
    },
  },
  rectangle:{
    width:260,
    height:80,
    background:theme.palette.primary.main,
    marginLeft:200,
    marginTop:60
    //position:'absolute',
    //bottom:0
  }
}))

const ScreenB = props => {

  const classes = useStyles()
  const {scroll, range, offset, content, color} = props

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  let showContent = scroll >= range.min + range.offset + offset && scroll < range.max + range.offset + offset 
  if(sm) showContent = scroll >= range.min + range.offset + offset -range.timemin2 && scroll < range.max + range.offset + offset - range.time2
  if(xs) showContent = scroll >= range.min + range.offset + offset -range.timemin && scroll < range.max + range.offset + offset - range.time

  let timeout = 750
  if(sm) timeout = 100
  
  return(
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Fade in={showContent} timeout={timeout}  >
              <div>
                <Grid container alignItems='flex-end' spacing={2} className={classes.header}>
                  <Grid item><img src={logo} alt='' className={classes.logo}/></Grid>
                  <Grid item xs><div className={classes.title}>{content.title}</div></Grid>
                </Grid>
              </div>
            </Fade>
            <Grid item xs={12}>
              <Fade in={showContent} timeout={timeout}>
                <div className={classes.subtitle}>{content.data}</div>
              </Fade>
            </Grid>
          </Grid>
        </Grid>   
        {/* <Fade in={showContent} timeout={750}>
          <div className={classes.rectangle}></div>
        </Fade> */}    
      </div>
    </div>
  )
}

export default withWidth()(ScreenB)