import React from 'react'
import { withStyles, Fade, Slide, Typography, Zoom, Icon } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import IconMask from './components/IconMask';
import LogoGeneralMask from './components/LogoGeneralMask';
import Manifiesto from './components/Manifiesto';
import manifiestB from '../../../../assets/carouselB.jpg'
import manifiestC from '../../../../assets/carouselC.jpg'
import manifiestD from '../../../../assets/carouselD.jpg'
import Manifiesto2 from './components/Manifiesto2';
import ManifiestoZoomIn from './components/ManifiestoZoomIn';
import white_logo from '../../../../assets/new_logos/ALTHAR_LOGO_CLARO_ICONO-08.svg'
import video from '../../../../assets/Althar_Credenciales.mp4'
import cx from 'classnames'
import { useState } from 'react';


//valores anteriores
/* const range_text_a = {min:0, max:800, offset:0}
const range_text_b = {min:0, max:800, offset:800}
const range_primary_icon = {min:0, max:1600, offset:0}
const range_overlay = {min:0, max:1600, offset:1600}
const range_img1 = {min:0, max:600, offset:1700}

const range_overlay2 = {min:0, max:1600, offset:4400}
const range_fade2 = {min:0, max:1200, offset:4400}
const range_img2 = {min:0, max:1000, offset:3300, }
const range_scale_zoomin = {min:0, max:2500, offset:3500} */

//const totalHeight = 7000
const totalHeight = 4000

//textos e icono
const range_text_a = {min:0, max:800, offset:0}
const range_text_b = {min:0, max:800, offset:800}
const range_primary_icon = {min:0, max:1600, offset:0}

//imagen viejita con logo mask
const range_overlay = {min:0, max:600, offset:1600}
const range_img1 = {min:0, max:600, offset:1600}

//imagen viejita natural con zoom y video
const range_img2 = {min:0, max:1400, offset:range_img1.offset + range_img1.max }
const range_scale_zoomin = {min:0, max:5000, offset:range_img2.offset + 300}

const SectionA = ({scroll_position, classes, actions, onChangeModal, modals}) => {

    //console.log(scroll_position)

    let text_a_fade = actions.onFadeText(range_text_a)
    let text_b_fade = actions.onFadeText(range_text_b)

    let img1_fade = actions.onFadeText(range_img1)
    let overlay_fade = actions.onFadeText(range_overlay)
    let overlay_scale = actions.onScaleSVG(range_overlay)
    
    let zoomin_scale = actions.onScaleSVG(range_scale_zoomin)
    let img2_fade = actions.onFadeText(range_img2)

    console.log(scroll_position)

    const [play_reel, setPlayReel] = useState(false)

    return(
      <div className={classes.root}> 
        <div className={classes.emptySpace}/>
        <div className={classes.container} style={{overflow:'hidden', }}>
          <div style={{overflowX:'hidden'}}>              
            <Slide direction='right' in={actions.onItemValid(range_text_a)} timeout={1000}>
              <div  className={classes.txtWrapper} >
                <div className={classes.textB_container} style={{color:fade('#000', text_a_fade)}}>
                  <div align='left' className={classes.letter}>Discover.</div>
                  <div align='left' className={classes.letter}>Design.</div>
                  <div align='left' className={classes.letter}>Delight.</div>
                </div>
              </div> 
            </Slide>                         
            <Fade in={actions.onItemValid(range_text_b)} timeout={500}>
              <div className={classes.textB_wrapper}>
                <div className={classes.textB_container} style={{color:fade('#000', text_b_fade)}}>
                  A life full of
                  health, happiness 
                  and hope.
                </div>
              </div>
            </Fade>
            <IconMask visibility={actions.onItemValid(range_primary_icon)}/>
          </div>   
          {scroll_position > range_img1.offset -50 && scroll_position < range_img1.offset + range_img1.max ? (
            <div className={classes.imageContainer}>
              <Manifiesto fade_in={img1_fade} />
              <div className={classes.overlay} 
              style={{background:fade("#00adb5",overlay_fade),}}
              />
              {scroll_position < range_img1.offset + range_img1.max ? <LogoGeneralMask scale={overlay_scale}/> : null }             
            </div>
          ) : null}
          {scroll_position > range_img2.offset -50 ? (
            <div className={classes.imageContainer}>
              <ManifiestoZoomIn scale={zoomin_scale} scroll={scroll_position} />  
              {scroll_position > range_img2.offset + 300 ? (
                <div >
                  <div onClick={()=>onChangeModal('reel', true)} className={classes.play_container} >
                    <div style={{position:'relative', height:'100%', width:'100%'}} >
                      <img src={white_logo} width={400} />
                      <div style={{position:'absolute', top:'63%', left:'50%', transform:'translate(-50%, -50%)', cursor:'pointer'}} >
                        <Icon style={{fontSize:100}} >play_circle</Icon>
                        {/* <Typography  >Watch reel</Typography> */}
                      </div>  
                    </div>  
                  </div> 
                  <div className={cx({[classes.reel_container]:true, 
                                      [classes.reel_container_open]:modals.reel
                                    })} >
                    {modals.reel ? 
                    <div style={{width:'100%', height:'100%'}} >
                      <video width="100%" height='100%' controls autoPlay  >
                          <source src={video} type="video/mp4" />
                      </video>
                    </div> : null}
                    {modals.reel ? 
                    <div onClick={()=>onChangeModal('reel', false)} className={classes.close_modal_button} >
                      
                        <Icon style={{color:'grey'}} >close</Icon>
                      
                    </div> : null}
                  </div>
                </div> 
              ) : null}         
            </div>
          ) : null}
          

          {/* {scroll_position > range_text_b.max+range_text_b.offset + 2050 && scroll_position < range_text_b.max+range_text_b.offset + 2800 ? (
            <div className={classes.imageContainer} style={{visibility:'visible'}}>
              <Fade in={scroll_position > range_text_b.max+range_text_b.offset + 2050} timeout={700}>
                <img src={manifiestB} className={classes.image} style={{opacity: img2_fade}} />     
              </Fade>      
            </div>
          ) : null}   
          {scroll_position > range_text_b.max+range_text_b.offset + 2950 && scroll_position < range_text_b.max+range_text_b.offset + 3800 ? (
            <div className={classes.imageContainer} style={{visibility:'visible'}}>
              <Manifiesto2 />
              <div className={classes.overlay} 
              style={{background:fade("#fff",overlay_fade2),}}
              />
              {scroll_position < 4900 ? <LogoGeneralMask scale={overlay_scale2}/> : null }         
            </div>
          ) : null}
          {scroll_position > range_text_b.max+range_text_b.offset + 3750  ? (
            <div className={classes.imageContainer} style={{visibility:'visible'}}>
              <Manifiesto2 />    
            </div>
          ) : null} */}

          {/* foto de la familia */}
          {/* {scroll_position > range_text_b.max+range_text_b.offset + 3450  ? (
            <div className={classes.imageContainer} style={{visibility:'visible'}}>
              <Slide in={scroll_position > range_text_b.max+range_text_b.offset + 3500} timeout={2000} direction="up">
                <img src={manifiestD} className={classes.image} /> 
              </Slide>            
            </div>
          ) : null} */}
        </div>   
      </div>
    )
}

//viejita anterior
{/* <div className={classes.imageContainer} style={{visibility:'visible'}}>
              <Zoom in={scroll_position > range_text_b.max+range_text_b.offset + 2200} timeout={2000}  >
                <img src={manifiestC} className={classes.image} />  
              </Zoom>           
            </div> */}


const styles = theme => ({
  root:{
    height:`${totalHeight}px`,
    position:'relative',
  },
  emptySpace:{
    height:`calc(${totalHeight}px - 100vh)`,
    position:'absolute',
    top:0,
    left:'50%',
    width:'1px',
    visibility:'hidden',
    boxSizing:'border-boxSizing',
  },
  play_container:{
    position:'absolute', top:'10%', left:'50%', 
    transform:'translateX(-50%)',  
    //cursor:'pointer', 
    
  },
  reel_container:{
    position:'absolute', 
    width:10, 
    height:10, 
    background:'black', 
    bottom:-10, 
    left:-10,
    transition: theme.transitions.create(['transform','width','height'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    })

  },
  reel_container_open:{
    width:'100%',
    height:'100%',
    bottom:0,
    left:0
    //top:0
  },
  close_modal_button:{
    position:'absolute', 
    top:20, 
    right:20, 
    width:40, height:40,
    cursor:'pointer'
  },
  container:{
    width:'100%',
    height:'100vh',
    position:'sticky',
    top:'0px',
  },
  imageContainer:{
    position:'relative',
    width:'100%',
    height:'100vh',
    //visibility:'hidden',
    background:'transparent',
    //background:'red'
  },
  image:{
    width:'100%',
    height:'100%',
    objectFit:'cover',
    [theme.breakpoints.down('md')]: {
      //objectFit:'contain',
      height:'100%'
    },
  },
  letter:{
    fontFamily:theme.typography.fontFamily,
    fontSize:170,
    fontWeight:600,
    lineHeight:0.9,
    padding:0,
    marginLeft:'18%',
    //background:'red',
    [theme.breakpoints.only('lg')]: {
      fontSize:170,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:140,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize:100,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize:70,
    },
  },
  txtWrapper:{
    position:'absolute',
    top:'25%',
    left:'10%',
    //transform: 'translate(-50%, -50%)',
    zIndex:10,
    //background:'blue'
  },
  textB_wrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%, -50%)',
    zIndex:10,
    //background:'pink'
  },
  textB_container:{ 
    //width:'100%',
    fontFamily:theme.typography.fontFamily,
    fontSize:170,
    fontWeight:600,
    lineHeight:0.9,
    //background:'red',
    [theme.breakpoints.only('lg')]: {
      width:1200,
      fontSize:140,
    },
    [theme.breakpoints.only('md')]: {
      width:900,
      fontSize:120,
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      fontSize:100,
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      fontSize:70,
    },
  },
  overlay:{
     
    width:'100%', 
    height:'100%',
    top:0,
    left:0,
    position:'absolute',
    zIndex:1
  }
})

export default withStyles(styles)(SectionA)