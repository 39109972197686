import React from 'react'
import { makeStyles, Grid, Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root:{
    position:'absolute',
    right:0,
    width:600, 
    height:'5500px', 
    //zIndex:100,
    //zIndex:70,
    //background:'green',
    marginTop:500,
    [theme.breakpoints.only('lg')]: {
      width:500,
    },
    [theme.breakpoints.only('md')]: {
      width:370,
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      //height:'4500px'
    },
    
  },
  container:{
    margin:'100px 32px',
    //background:'red'
  }
  
}))

const SideContent = props => {

  const classes = useStyles()
  const {scroll, range, offset, language} = props

  const content = contentData[language]

  const isValid = scroll > offset + range.min + range.offset
 

  return(
    <div className={classes.root} 
    style={{visibility: isValid ? 'visible' : 'hidden'}}>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <div style={{width:'100%', height:400}}/>
          </Grid>
          <Grid item xs={12}>
            <ContentView data={content.health}/>
          </Grid>
          <Grid item xs={12}>
            <div style={{width:'100%', height:400}}/>
          </Grid>
          <Grid item xs={12}>
            <ContentView data={content.technology}/>
          </Grid>
          <Grid item xs={12}>
            <div style={{width:'100%', height:400}}/>
          </Grid>
          <Grid item xs={12}>
            <ContentView data={content.strategy}/>
          </Grid>
          {/* <Grid item xs={12}>
            <div style={{width:'100%', height:400}}/>
          </Grid>
          <Grid item xs={12}>
            <ContentView data={content.commercial}/>
          </Grid> */}
        </Grid>
      </div>
    </div>
  )
}

export default SideContent

const useStylesView = makeStyles(theme => ({
  root:{
    width:300,
    [theme.breakpoints.only('md')]: {
      width:'100%',
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
    },
  },
  title:{
    //color:'white',
    fontWeight:700,
    fontSize:32,
    [theme.breakpoints.only('xs')]: {
      fontSize:24
    },
  },
  data:{
    //color:'white',
    fontSize:24,
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      fontSize:18
    },
  },
  icon:{
    //color:'white',
    fontSize:14,
    [theme.breakpoints.only('xs')]: {
      fontSize:10
    },
    marginTop:10
  }
}))

const ContentView = props => {

  const {data} = props
  const classes = useStylesView()

  return(
    <div className={classes.root}>
      <Grid container>
        {data.map((item,key)=> {
          return(
            <Grid item xs={12} key={key.toString()}>
              <div style={{marginBottom:100}}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={classes.title}>{item.title}</div>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {item.items.map((el,id)=>{
                        return(
                          <Grid item xs={12} key={id.toString()}>
                            <Grid container wrap='nowrap' spacing={1}>
                              <Grid item><Icon className={classes.icon}>brightness_1</Icon></Grid>
                              <Grid item><div className={classes.data}>{el}</div></Grid>
                            </Grid>
                            
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const contentData = {
  english:{
    health:[
      {
        title:'Medical',
        items:
        [
          'Medical Education',
          'Medical writing',
          'Medical Marketing',
          'Scientific Content Development',
          'Content Management (Digital and Print)',
          'MSL & Rep Training',
          'Advisory boards (Adboards)',
          'Consensus Meetings',
        ]
      },
      /* {
        title:'Patients',
        items:
        [
          'Patient Journeys',
          'Disease Awareness',
          'Promo & Educational Materials',
          'Social Platform Content',
          'Health Prevention Programs',
          'PRM & Data Management',
          'Adboards',
          'Disease Management Programs',
        ]
      }, */
      {
        title:'Education',
        items:
        [
          'Speaker Trainings',
          'Customized Content',
          'Stand Alone (Summits)',
          'Workshops',
          'Learning Management Systems',
          'elearning & Mobile Learning'
        ]
      },
    ],
    technology:[
      {
        title:'Digital',
        items:
        [
          'Websites & Mobile Websites',
          'APP Development',
          'LMS & E-learning',
          'Content Platforms',
          'Social Media Campaigns & Management',
          'Webcast (Live & On Demand)',
          'Customized Software'
        ]
      },
      {
        title:'Audiovisual',
        items:
        [
          'Video production: Motion Graphics, 2D & 3D',
          'Live events audiovisual production',
          'Virtual events audiovisual production',
          'Local, regional and worldwide',
        ]
      },
      /* {
        title:'Software',
        items:
        [
          'Software As A Service',
          'Customized Software',
          'Data Mining & Management',
          'Electronic Medical Records',
          'RWE Platforms',
        ]
      },
      {
        title:'Disruption',
        items:
        [
          'IoT',
          'AI',
          'Digital Therapeutics',
          'Medical Devices',
          'Bioelectronics',
          'Telemedicine',
          'E-pharmacy'
        ]
      } */
    ],
    digital:[
      {
        title:'Digital',
        items:
        [
          'Patient Journeys',
          'Awareness, Trial and Usages (ATU’s)',
          'Epidemiology and Outcomes Research',
          'Evidence Networks',
          'Panels',
          'Integrated Evidence Generation Plans',
          'GAP Assessments',
          'Stakeholder Value Maps',
          'Digital Transformation',
          'Digital Health',
          'E-commerce Strategy',
          'E-learning Strategy',
          'Innovation and Insight',
          'Product Launch Strategies',
          'Life Cycle Management',
          'Regulatory and Compliance',
          'Competitive Analysis'
        ]
      },
    ],
    strategy:[
      {
        title:'Consulting & Research:',
        items:
        [
          'Digital Transformation',
          'Digital health',
          'Market Research',
          'Market Access',
          'RWE/RWD',
          'Brand Building',
          'Product Launches',
          'Communications Campaign',
          'Medical Campaign',
          'Value Proposition Frames',
          'Competitive Frames',
          'Customer Engagement',
          'Strategy, Planning & Development',
          'eCommerce Strategy',
          'eLearning Strategy',
          'Life Cycle Management'
        ]
      },
      /* {
        title:'',
        items:
        [
          'Customer Engagement',
          'Strategy, Planning & Development',
          'eCommerce Strategy',
          'eLearning Strategy',
          'Life Cycle Management'
        ]
      }, */
    ],
    
    commercial:[
      {
        title:'Marketing',
        items:
        [
          'Understand Your Market',
          'Build Your Brand',
          'Launch Successfully',
          'Optimize Values',
          'Communication Campaign',
          'Value Proposition Frames',
          'Competitive Frames',
        ]
      },
      {
        title:'Content',
        items:
        [
          'Customized Content',
          'Enduring Materials',
          'Social Media & Digital Content',
        ]
      },
      {
        title:'E-commerce',
        items:
        [
          'Strategy, Planning & Development',
          'Analytics & Optimization',
          'Marketplaces Management',
          'Media Planning',
          'SEO / SEM',
        ]
      },
    ],
  }
}

