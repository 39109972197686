import React from 'react'
import { makeStyles, Slide, withWidth } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import AltharLogo from './AltharLogo';
import logo from '../../../../../assets/new_logos/ALTHAR_LOGO_CLARO_TEXTO-05.svg'


const useStyles = makeStyles(theme => ({
  root:{
    position:'absolute',
    zIndex:1,
    width:'100%',
    height:'100vh',
    //background:'red'
  },
  container:{
    width:1400,
    margin:'auto',
    color:'white',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('xl')]: {
      width:1400,
    },
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 50px',
      //background:'red'
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 32px'
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      padding:'0px 30px'
    },
  },
  subcontainer:{
    position:'absolute',
    marginTop:100,
    marginBottom:100,
    bottom:0,
    //background:'red',
    [theme.breakpoints.only('lg')]: {
      marginBottom:80,
    },
    [theme.breakpoints.only('md')]: {
      marginBottom:100,
    },

  },
  title:{
    fontSize:64,
    fontWeight:800,
    '& strong':{
      color:theme.palette.primary.main,
      fontWeight:800
    },
    [theme.breakpoints.only('lg')]: {
      fontSize:48,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:42,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize:30,
    },
  },
  subtitle:{
    fontSize:54,
    fontWeight:500,
    width:1200,
    [theme.breakpoints.only('lg')]: {
      width:900,
      fontSize:42,
    },
    [theme.breakpoints.only('md')]: {
      width:800,
      fontSize:36,
    },
    [theme.breakpoints.only('sm')]: {
      width:500,
      marginLeft:50,
      fontSize:36,
    },
    [theme.breakpoints.only('xs')]: {
      width:280,
      fontSize:26,
    },
  },
  logoWrapper:{
    position:'absolute', 
    bottom:160,
    left:-64,
    //background:'red',
    [theme.breakpoints.only('xl')]: {
      bottom:220,
    },
    [theme.breakpoints.down('md')]: {
      left:0
      //background:'red'
    },
    [theme.breakpoints.only('sm')]: {
      bottom:260
    },
    [theme.breakpoints.only('xs')]: {
      bottom:260,
      left:12
    },
  },
  logo:{
    width:1000,
    [theme.breakpoints.down('md')]: {
      width:700,
    },
    [theme.breakpoints.only('sm')]: {
      width:500,
      marginLeft:55
    },
    [theme.breakpoints.only('xs')]: {
      width:300,
    },
  }
}))

const scales = {
  'xs':1.8,
  'sm':1.8,
  'md':1.1,
  'lg':1.4,
  'xl':1.8,
}

const ScreenA = props => {

  const classes = useStyles()
  const {scroll, range, fade_in, language, offset, width} = props

  const content = contentData[language]

  

  return(
    <div className={classes.root}>
      <div className={classes.container}>
          <div className={classes.logoWrapper}>
            <Slide direction='left' in={scroll > range.min + range.offset + offset} timeout={1200}>
              {/* <div className={classes.logo}><AltharLogo color='#FFF' opacity={fade_in} scale={scales[width]}/></div> */}
              <div ><img src={logo} alt='' className={classes.logo} style={{opacity: fade_in !== null && fade_in !== undefined ? fade_in : 1}} /></div>
            </Slide>
          </div>
          <div className={classes.subcontainer}>
            <Slide direction='right' in={scroll > range.min + range.offset + offset} timeout={1200}>
              <div className={classes.subtitle} style={{color:fade('#FFF', fade_in)}}>{content.title}</div>
            </Slide>
          </div>
        
      </div>
    </div>
  )
}

export default withWidth()(ScreenA)

const contentData = {
  english:{
    title:'With our services and solutions, we become more than a vendor, we become your dream partner.',
    data:'We help ambitious design, innovation and technology-driven businesses differentiate, compete and win. We are at the forefront of present creating change through our values and beliefs that influence our state-of-the-art work and the impact we are achieving with our clients. In addition to our more than 100 collaborators and offices in Chicago, Mexico City, Sao Paulo, Bogota and Santiago.'
  }
}