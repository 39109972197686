import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import image from '../../assets/LMS-&-learning.png'

const LMS = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <div  >
                <Typography className={classes.title} variant='h2'>LMS &</Typography>
                <Typography className={classes.title} variant='h2'>E-learning</Typography>
                
            </div>
            <div className={classes.challenge}>
                <Typography className={classes.subtitle} variant='subtitle1'>Challenge.</Typography>
                <Typography className={classes.text}>
                    Each year in Mexico 55k physicians take the ENARM (National Exam for
                    Medical Residences), and less than 9% manage to pass it. Most courses are face-to-face
                    and in the 3 main cities of the country. The online courses are incomplete,
                    expensive and not friendly at all.
                </Typography>
            </div>
            <div className={classes.solution}>
                <Typography className={classes.subtitle} variant='subtitle1'>Solution.</Typography>
                <Typography className={classes.text}>
                    CeluClin, the first EMR designed for a user-friendly 
                    experience. Mobile-first, simple, complete and with
                    artificial intelligence algorithms in order to
                    avoid medical errors in diagnostics and therapeutics,
                    including a patient interface.
                </Typography>
            </div>
            <div className={classes.result}>
                <Typography className={classes.subtitle} variant='subtitle1'>Result.</Typography>
                <Typography className={classes.text}>
                    In 2019, nearly 400 physycians pass the examen all will be specialists thanks to
                    Pre-ENARM.
                </Typography>
            </div>
            {/* <div className={classes.img_container}>
                <img src={image} alt='' className={classes.img} />
            </div> */}
            <img src={image} alt='' className={classes.img} />
            <div className={classes.line_container} style={{top:'24%', left:'9%'}}>
                <div className={classes.line2} style={{width:50, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:600, marginTop:-16, marginLeft:-6}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'64%', left:'8%'}}>
                <div className={classes.line2} style={{width:30, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:320, marginTop:-10, marginLeft:-3}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'38%', right:'10%'}}>
                <div className={classes.line} style={{width:190, marginTop:-16, marginRight:-5}}></div> 
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>        
            </div>
        </div>
    )
}

export default LMS

const useStyles = makeStyles(theme => ({
    root:{
      background:theme.palette.primary.main,
      //background:'red',
      height:'100vh',
      padding:'5%',
      paddingTop:'2%',
      color:'white',
      position:'relative'
    },
    title:{
        lineHeight:0.9,
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
          },
    },
    subtitle:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.2rem'
          },
    },
    text:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.9rem'
          },
    },
    line_container:{
        position:'absolute',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
    },
    line:{
        height:0.5,
        background:'white',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        //width:180,
    },
    line2:{
        height:0.5,
        background:'white',
        width:60,
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        
    },
    challenge:{
        position:'absolute',
        top:'26%',
        //bottom:'50%',
        left:'5%',
        //paddingTop:'50%',
        //paddingTop:'5%',
        maxWidth:360,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'green',
            top:'auto',
            left:0,
            marginTop:12,
            maxWidth:300
            
          },
        [theme.breakpoints.down('xs')]:{
            maxWidth:'100%'
          },
        
    },
    solution:{
        position:'absolute',
        top:'42%',
        right:'5%',
        maxWidth:260,
        textAlign:'right',
        //background:'black'
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'blue',
            top:'auto',
            right:0,
            left:0,
            textAlign:'left',
            margin:'12px 0px'
        },
        [theme.breakpoints.down('xs')]:{
            maxWidth:'100%'
          },
        
    },
    result:{
        position:'absolute',
        //top:'63%',
        top:'65%',
        //right:'30%',
        left:'5%',
        maxWidth:340,
        [theme.breakpoints.down('md')]:{
            //left:'5%'
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:'auto',
            left:0, 
        },
        
    },
    img_container:{
        position:'absolute',

        top:-10,
        bottom:10,
        height:100,
        //left:'50%',
        background:'black'
    },
    img:{
        //width:'100%',
        //height:'100%',
        //transform:'rotate(10deg)',
        objectFit:'cover',
        position:'absolute',
        top:'30%',
        left:'34%',
        //right:'15%',
        //right:'20%',
        //background:'black',
        width:'40%',
        [theme.breakpoints.only('md')]:{
            //transform:'scale(0.8) rotate(25deg)',
            //right:'10%',
            //top:-20
            display:'none'
        },
        [theme.breakpoints.down('sm')]:{
            //transform:'scale(0.8) rotate(25deg)',
            left:'50%',
            top:'35%'
        },
        [theme.breakpoints.down('xs')]:{
            display:'none'
        }

        //objectPosition:'center'
        //bottom:20
    }
    
  }))