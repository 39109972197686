import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import manifiestA from '../../../../../assets/PNG/PNG/WEBnegro_08.png'
import manifiestA from '../../../../../assets/carouselC.jpg'
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';


const ManifiestoZoomIn = ({classes, scroll, range, width, max_visibility, scale}) => {

  const vector_size = {
    'xs': {width:625,height:197, offset_x:260, offset_y:-10, scale:0.2},
    'sm': {width:625,height:197, offset_x:150, offset_y:-10, scale:0.5},
    'md': {width:625,height:197, offset_x:142, offset_y:-16, scale:0.7},
    'lg': {width:625,height:197, offset_x:106, offset_y:-16, scale:1},
    'xl': {width:625,height:197, offset_x:70, offset_y:-16, scale:1},
  }

  const vector_size_C = {
    'xs': {width:625,height:197, offset_x:300, offset_y:200, scale:0.5},
    'sm': {width:625,height:197, offset_x:240, offset_y:120, scale:1.2},
    'md': {width:625,height:197, offset_x:200, offset_y:170, scale:1.4},
    'lg': {width:625,height:197, offset_x:30, offset_y:140, scale:5},
    'xl': {width:625,height:197, offset_x:0, offset_y:140, scale:1.8},
  }

  const [local_window, setWindow] = useState({
    width:-1000,
    height:-1000
  })

  let myContainer = useRef()

  useEffect(() => {
    let temp = {...local_window}
    const height = myContainer.clientHeight;
    const width = myContainer.clientWidth
    temp.height = height
    temp.width = width
    setWindow(temp)
  }, [])


  const logo = vector_size[width]

  //const start_width = 1332
  //const start_height = 642

  const start_width = 512
  const start_height = 930

  let current_scale = 1 - (1-scale)

  //const x_offset = local_window.width/2 - (start_width*current_scale)/2
  //const y_offset = local_window.height/2 - (start_height*current_scale)/2 + (scale-1)*250

  const x_offset = local_window.width/2 - (start_width*current_scale)/2
  const y_offset = local_window.height/2 - ((start_height)*current_scale)/2 

  console.log(local_window.width)
  console.log(current_scale)
  console.log(x_offset)

    return(
      <div className={classes.root} ref={(ref) => {myContainer = ref}}>
        <img src={manifiestA} alt='' className={classes.image} 
        
        style={{
          //visibility: scroll < max_visibility ? 'visible' : 'hidden',
          //transform:`translate(calc(0% - ${logo_offset_x}px), calc(-50% - ${logo_offset_y}px)) scale(${logo_scale})`,
          transform:`translate(0px, calc(0% - ${y_offset}px)) scale(${current_scale})`,
        }}
        />
      </div>
    )
  
}

const styles = theme => ({
  root:{
    width:'100%',
    height:'100vh',
  },
  image:{
    /* position:'absolute',
    top:'50%',
    right:'50%',
    transform:'translate(50%,-50%)',
    width:1000 */
    width:'100%',
    height:'100%',
    objectFit:'cover',
    /* transition: theme.transitions.create(['transform','width','height'], {
      easing: theme.transitions.easing.easeIn,
      duration: 1000,
    }), */
    
  },
 
})

export default withStyles(styles)(ManifiestoZoomIn)