import React from 'react'
import {makeStyles } from '@material-ui/core'
import SectionA from './sections/SectionA/SectionA';
import SectionB from './sections/SectionB/SectionB';
import SectionC from './sections/SectionC/SectionC';
import SectionD from './sections/SectionD/SectionD';
import SectionE from './sections/SectionE/SectionE';
import NavBar from './components/NavBar/NavBar';
import useMainView from './useMainView';
import SectionF from './sections/sectionF/SectionF';
import ContactView from '../ContactView/ContactView';
import { useEffect } from 'react';
import SectionEa from './sections/SectionE/SectionEa';

const MainView = ({language, history, topic_modal, onChangeModal, modals}) =>{

  const classes = useStyles()
  const {scroll_position, actions, } = useMainView({})
  
  
  useEffect(() => {
    if(scroll_position > 19300){
      //console.log('cerrar modal')
      //console.log(modals.topic)
      onChangeModal('topic', false)
    }
  }, [scroll_position > 19300])


  return(
    <div className={classes.root}>
      {/* {!modals.internal_modal ? <NavBar history={history} /> : null} */}
      <SectionA onChangeModal={onChangeModal} modals={modals} language={language} scroll_position={scroll_position} offset={0} actions={actions}/>
      <SectionB language={language} scroll_position={scroll_position} offset={4000} actions={actions}/>
      <SectionC onChangeValueModal={onChangeModal} modal={modals.topic} language={language} scroll_position={scroll_position} offset={12300} />
      <SectionD onCloseModal={()=>onChangeModal('topic', false)} language={language} scroll_position={scroll_position} offset={16800} actions={actions}/>
      <SectionEa onChangeModal={onChangeModal} language={language} scroll_position={scroll_position} offset={23000} actions={actions}/>
      <SectionE onChangeModal={onChangeModal} language={language} scroll_position={scroll_position} offset={23300} actions={actions}/>
      <SectionF language={language} scroll_position={scroll_position} offset={24300} actions={actions}/>
    </div>
  )

}


const useStyles = makeStyles(theme => ({
  fab:{
    top:33,
    right:77,
    position:'fixed',
    background:theme.palette.primary.main,
    color:'white',
    zIndex:1,
    '&:hover':{
      background:theme.palette.primary.main,
    }
  }
}))

export default MainView