import React, { Component } from 'react';
import PublicRouters from './routes/router.jsx';
import { createBrowserHistory } from "history";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import WebsiteLayout from './layouts/WebsiteLayout/WebsiteLayout.jsx';
import Avenir from './fonts/Avenir.ttc'
import CssBaseline from "@material-ui/core/CssBaseline";

const avenir = {
  fontFamily: 'Avenir',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: '500',
  src: `
    local('Avenir'),
    url(${Avenir}) 
  `,
  
 };

const theme = createMuiTheme({
  palette: {
    primary: { main: '#00adb5' }, // Purple and green play nicely together. #091635 //'#D64189' magneta
    secondary: { main: '#393e46' }, // This is just green.A700 as hex. #00acc1 '#4466f2'  rgba(52,33,107.1) 
    custom: {main: 'rgba(52,33,107,1)'} //2196F3
  },
  typography: {
    useNextVariants: true,
    fontFamily:[
      'Avenir LT Std',
      //'Lato',
    ].join(','),
  },
  overrides:{
    MuiTypography:{
      h1:{
        fontSize:'7rem',
        fontWeight:900
      },
      h2:{
        fontSize:'4rem',
        fontWeight:900
      },
      h3:{
        fontSize:'3.5rem',
        fontWeight:600
      },
      h4:{
        fontWeight:600,
        fontSize:'2.5rem',
      },
      h5:{
          fontSize:'1.875rem',
          fontWeight:600
      },
      h6:{
        fontSize:'1.5rem',
        //fontWeight:600,
      },
      subtitle1:{
        fontSize:'1.5rem',
        fontWeight:900,
      },
      subtitle2:{
        fontSize:'1rem',
        fontWeight:700,
      },
      body1:{
          fontSize:'1.2rem',
          //fontWeight:500,
      },
      body2:{
          fontSize:'1rem',
          fontWeight:500,
      },
      caption:{
        fontSize:'0.875rem',
        fontWeight:500,
      },
      button:{
        fontSize:'1.125rem'
      },
    },
    /* MuiCssBaseline: {
      "@global": {
        '@font-family': [avenir]
      }
    } */

  }
});

const bannerOn = false

class App extends Component {
  render() {

    return (
      <MuiThemeProvider theme={theme}>
        {/* <CssBaseline /> */}
        <div>
          {/* <PublicRouters history={createBrowserHistory()} language={'english'} banner={bannerOn}/> */}
          <WebsiteLayout history={createBrowserHistory()} language={'english'} banner={bannerOn} />
        </div>
          
      </MuiThemeProvider>
    );
  }
}

export default App;
