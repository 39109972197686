import React from 'react'
import {Grid, makeStyles, Slide, Typography } from '@material-ui/core'
import useUsView from './useUsView';
import logo from '../../assets/new_logos/ALTHAR_LOGO_VERDE_TEXTO-02-02.svg'

const UsView = ({language}) =>{

  const classes = useStyles()
  const content = contentData[language]
  const {scroll_position, actions, modals} = useUsView({})

  const items = Object.values(content)
  //console.log(items)

  return(
    <div className={classes.root}>  
      <Slide direction='left' in={true}  timeout={1200}>
        <div className={classes.title_container}>
            <img src={logo} alt='' className={classes.logo}/>
            <Typography variant='h1' className={classes.title} >us</Typography>
        </div>
      </Slide> 
      <div className={classes.values_container}>
        <Grid container spacing={5} direction='column'>
            {items.map((el, ind) => {
              return(
                <Grid item key={ind.toString()} >
                  <Slide direction='left' in={true} timeout={el.timeout}>
                    <div>
                      <Typography className={classes.item_title}>{el.title}</Typography>
                      <Typography className={classes.text_item} >{el.data}</Typography>
                    </div>
                  </Slide>
                </Grid>
              )
            })}
        </Grid>
      </div>
    </div>
  )

}


const useStyles = makeStyles(theme => ({
  root:{
    position:'relative',
    padding:40,
    [theme.breakpoints.down('sm')]: {
      padding:20
    },
    
  },
  item_title:{
    fontSize:'3.5rem',
    fontWeight:900,
    [theme.breakpoints.down('sm')]: {
      fontSize:'2rem'
    },
  },
  text_item:{
    fontSize:'1.5rem',
    maxWidth:500,
    [theme.breakpoints.down('sm')]: {
      fontSize:'1rem',
      maxWidth:300,
      //background:'red'
    },
  },
  title:{
    marginLeft:24,
    marginTop:12,
    [theme.breakpoints.down('sm')]: {
      marginLeft:12,
      marginTop:-40,
      fontSize:'3.5rem'
    },
  },
  title_container:{
    position:'relative',
    display:'flex',
    alignItems:'center',
    [theme.breakpoints.down('sm')]: {
      display:'block',
    },
  },
  values_container:{
    //marginLeft:40,
    marginTop:40,
    padding:2,
    //width:'100%',
    //background:'red'
  },
  logo:{
    width:400,
    paddingBottom:12,
    [theme.breakpoints.down('sm')]: {
      width:300,
      paddingBottom:0,
      //background:'red'
    },
    [theme.breakpoints.down('xs')]: {
      width:200,
      //paddingBottom:0
    },
  }
}))

const contentData = {
  english:{
    sectionA:{
      title:'Mission',
      data:'We are transforming health with technology, data, design and frenzy for life.',
      timeout:1000
    },
    sectionB:{
      title:'Courage',
      data:'Enthusiasm, true conviction and fearless belief lead our standards way beyond expectations.',
      timeout:1200
    },
    sectionC:{
      title:'Character',
      data:'Mixing our talents and moral quality; we have the right motives to achieve greater results.',
      timeout:1400
    },
    sectionD:{
      title:'Integrity',
      data:'We always do the right things, building up strong and lasting working relationships.',
      timeout:1600
    },
    sectionE:{
      title:'Innovation',
      data:'We strive toward to create and design better health solutions and services.',
      timeout:1800
    }
  }
}

export default UsView