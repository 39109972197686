import React, { useState, useEffect, useRef } from 'react'
import './stylestext.css'
import { withWidth } from '@material-ui/core';
import logo from '../../../../../assets/new_logos/ALTHAR_LOGO_VERDE.svg'

const vector_size = {
  'xs': {width:1332,height:642, offset_x:0, offset_y:0, scale:0.61},
  'sm': {width:1332,height:642, offset_x:0, offset_y:0, scale:0.765},
  'md': {width:1332,height:642, offset_x:0, offset_y:0, scale:1.3},
  'lg': {width:1332,height:642, offset_x:0, offset_y:0, scale:1.3},
  'xl': {width:1332,height:642, offset_x:0, offset_y:0, scale:1.53},
}

const LogoGeneralMask = ({scale, width}) => {

  const [local_window, setWindow] = useState({
    width:-1000,
    height:-1000
  })

  let myContainer = useRef()

  useEffect(() => {
    let temp = {...local_window}
    const height = myContainer.clientHeight;
    const width = myContainer.clientWidth
    temp.height = height
    temp.width = width
    setWindow(temp)
  }, [])

  const updateDimensions = () => {
    let temp = {...local_window}
    const height = myContainer.clientHeight;
    const width = myContainer.clientWidth
    temp.height = height
    temp.width = width
    setWindow(temp);
  }

  const logo = vector_size[width]

  //const start_width = 1332
  //const start_height = 642

  const start_width = 512
  const start_height = 408

  let current_scale = logo.scale - (1-scale)

  //const x_offset = local_window.width/2 - (start_width*current_scale)/2
  //const y_offset = local_window.height/2 - (start_height*current_scale)/2 + (scale-1)*250

  const x_offset = local_window.width/2 - (start_width*current_scale)/2
  const y_offset = local_window.height/2 - ((start_height + 50)*current_scale)/2 



  //console.log(y_offset)

  return(
    <svg height="100%" width="100%" ref={(ref) => {myContainer = ref}}>
      
      <defs>
        <mask id="mask" x="0" y="0" height="100%" width="100%">
          <rect x="0" y="0" height="100%" width="100%" />
            <g transform={`translate(${x_offset},${y_offset})scale(${current_scale})`}>
              {/* <polygon fill="#00ADB5" points="250 180, 1000 180, 1000 500, 250 500" /> */} 
              {/* <path  d="M492.7,372.5l-195-337.6C279,2.3,232,2.3,213.3,34.9l-195,337.6C-0.4,405,23,445.6,60.6,445.6h389.9
                C488,445.6,511.4,405,492.7,372.5z M255.5,405.3c-66.8,0-121-54.2-121-121s54.2-121,121-121s121,54.2,121,121
                S322.3,405.3,255.5,405.3z"/> */}
                {/* <path  d="M492.7,372.5L297.7,34.9C279,2.3,232,2.3,213.3,34.9L18.3,372.5C-0.4,405,23,445.6,60.6,445.6h389.9
                C488,445.6,511.4,405,492.7,372.5z M255.5,405.3c-66.8,0-121-54.2-121-121s54.2-121,121-121s121,54.2,121,121
                S322.3,405.3,255.5,405.3z"/> */}
                <path  d="M492.7,372.5L297.7,34.9C279,2.3,232,2.3,213.3,34.9L18.3,372.5C-0.4,405,23,445.6,60.6,445.6h389.9
                  C488,445.6,511.4,405,492.7,372.5z M255.5,405.3c-66.8,0-121-54.2-121-121s54.2-121,121-121s121,54.2,121,121
                  S322.3,405.3,255.5,405.3z"/>
                <circle  cx="255.5" cy="284.3" r="67.1"/>
            </g>
        </mask>
      </defs>
      <rect x="0" y="0" height="100%" width="100%" style={{fill: 'white'}}/>
    </svg>
  )
  
}

export default withWidth()(LogoGeneralMask)


{/* <svg height="100%" width="100%" ref={(ref) => {myContainer = ref}}>
      <defs>
        <mask id="mask" x="0" y="0" height="100%" width="100%">
          <rect x="0" y="0" height="100%" width="100%" />
            <g transform={`translate(${x_offset},${y_offset})scale(${current_scale})`}>
              <polygon fill="#00ADB5" points="250 180, 1000 180, 1000 500, 250 500" /> 
            </g>
        </mask>
      </defs>
      <rect x="0" y="0" height="100%" width="100%" style={{fill: 'white'}}/>
    </svg> */}