import React from 'react'
import { makeStyles, Grid, Slide } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { palette } from '../../../../../variables/config';


const useStyles = makeStyles(theme => ({
  root:{
    position:'absolute',
    zIndex:1,
    width:'100%',
    height:'100vh',
  },
  container:{
    width:1400,
    margin:'auto',
    color:'white',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('xl')]: {
      width:1400,
    },
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px'
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 50px'
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      padding:'0px 30px'
    },
  },
  subcontainer:{
    position:'absolute',
    marginTop:100,
    [theme.breakpoints.only('sm')]: {
      position:'initial'
    },
    [theme.breakpoints.only('xs')]: {
      position:'initial'
    },
  },
  title:{
    fontSize:64,
    fontWeight:800,
    '& strong':{
      color:theme.palette.primary.main,
      fontWeight:800
    },
    [theme.breakpoints.only('lg')]: {
      fontSize:48,
    },
    [theme.breakpoints.only('md')]: {
      fontSize:42,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize:42,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize:30,
    },
  },
  subtitle:{
    marginTop:100,
    fontSize:24,
    fontWeight:500,
    width:800,
    [theme.breakpoints.only('lg')]: {
      width:660,
      fontSize:20,
    },
    [theme.breakpoints.only('md')]: {
      width:540,
      fontSize:18,
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      fontSize:28,
    },
    [theme.breakpoints.only('xs')]: {
      marginTop:50,
      width:'100%',
      fontSize:16,
    },
  }
}))

const ScreenA = props => {

  const classes = useStyles()
  const {scroll, range, fade_in, language, offset} = props

  const content = contentData[language]

  return(
    <div className={classes.root}>
      <div className={classes.container}>
        
          <div className={classes.subcontainer}>
            <Slide direction='right' in={scroll > range.min + range.offset + offset} timeout={1200}>
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.title} style={{color:fade('#FFF', fade_in)}}>
                      {content.title[0]} <strong style={{color:fade(palette.primary.main, fade_in)}}>{content.title[1]}</strong> {content.title[2]}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.subtitle} style={{color:fade('#FFF', fade_in)}}>{content.data}</div>
                  </Grid>
                </Grid>
              </div>
            </Slide>
          </div>
        
      </div>
    </div>
  )
}

export default ScreenA

const contentData = {
  english:{
    title:['Building', 'galactic' ,'solutions with human-centered design and technology.'],
    data:'We help ambitious design, innovation and technology-driven businesses differentiate, compete and win. We are at the forefront of creating change through our values and beliefs that influence our state-of-the-art work and the impact we are achieving with our clients. In addition, we have more than 100 collaborators and offices in Chicago, Mexico City, Sao Paulo, Bogota and Santiago.'
  }
}