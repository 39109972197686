import React from 'react'
import { withStyles, fade, Slide, Typography, Fade, useTheme, useMediaQuery } from '@material-ui/core';
import cx from 'classnames'
import ScreenA from './components/ScreenA';
import sideImage from  '../../../../assets/sideImage.jpg'
import useSectionF from './useSectionF';
import logo from '../../../../assets/new_logos/ALTHAR_LOGO_VERDE_TEXTO-02-02.svg'
import ScreenB from './components/ScreenB';



const totalHeight = 1000

const range_first_screen = {min:0, max:1000, offset:0}
const range_second_screen = {min:0, max:1000, offset:1000}
const range_third_screen = {min:0, max:1000, offset:2000}
const range_fourth_screen = {min:0, max:1800, offset:3400}
const range_fifth_screen = {min:0, max:1500, offset:6400}
const range_side_screen = {min:0, max:5200, offset:1000}


const SectionF = ({classes, language, scroll_position, offset}) => {


  const content = contentData[language]
  const {actions} = useSectionF({scroll_position, offset})
  
  let fade_first_screen = actions.onFadeText(range_first_screen)

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  let final_offset = offset
  if(matches) final_offset = offset - 1000

  
  //console.log(scroll_position)
  //console.log(offset)

  return( 
    <div>
      <div className={cx({
        [classes.root]:true,
        //[classes.rootB]: scroll_position > offset + range_second_screen.min + range_second_screen.offset 

      })}> 
        <div className={classes.emptySpace} />
        <div className={classes.container} style={{overflow:'hidden', }}>
          <div>
            {/* <Fade in={scroll_position >= offset && scroll_position < range_first_screen.max  + offset} timeout={750}>
              <Slide direction='right' in={scroll_position > offset }  timeout={1200} >
                <div className={classes.logo_container}>
                    <img src={logo} alt='' className={classes.logo}/>
                    <Typography variant='h2' className={classes.title} >Insights</Typography>
                </div>
              </Slide>  
            </Fade>    
            <ScreenB content={content.insights}
              scroll={scroll_position} range={range_first_screen} offset={offset}/> */}

            <Fade in={scroll_position >= final_offset  } timeout={700}>
              <div className={classes.contact_root}>  
                <Slide direction='left' in={scroll_position > final_offset }  timeout={700}>
                  <div className={classes.contact_logo_container}>
                      <img src={logo} alt='' className={classes.contact_logo}/>
                  </div>
                </Slide> 
                <div className={classes.contact_info_container}>   
                  <a className={classes.link} target='_blank' href='info@althar.com' >info@althar.com</a>
                  <div style={{color:'white', marginTop:40}}>
                    <Typography>Palenque 369, Colonia Narvarte, Benito Juárez</Typography>
                    <Typography >Ciudad de México, CP 03400</Typography>
                    <Typography>+52 55 5335 1351</Typography>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )

}

const contentData = {
  english:{
    insights:{
      p1:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.”',
      p2:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.'
    },
  }
}

const styles = theme => ({
  generalContainer:{
    width:1400,
    margin:'auto',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px'
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 32px'
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      padding:'0px 32px'
    },
  },
  link:{
    position:'relative',
    zIndex:100,
    textDecoration:'none',
    color:theme.palette.primary.main,
    fontSize:'1.2rem',
  },
  root:{
    height:`${totalHeight}px`,
    //height:'100%',
    position:'relative',
    //background:theme.palette.primary.main,
    background:'black',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
  },
  rootB:{
    background:'transparent',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
  },
  backgroundImage:{
    position:'absolute',
    width:600,
    height:'100vh',
    top:0,
    right:0,
    filter:'blur(1px)',
    backgroundImage:`linear-gradient(90deg, 
      ${fade('#fff',0.85)}, 
      ${fade('#fff',0.35)}
    ), url(${sideImage})`,
    backgroundSize:'cover',
    [theme.breakpoints.only('lg')]: {
      width:500,
    },
    [theme.breakpoints.only('md')]: {
      width:370,
    },
    [theme.breakpoints.only('sm')]: {
      marginTop:400,
      width:'100%',
      right:'auto'
    },
    [theme.breakpoints.only('xs')]: {
      marginTop:400,
      width:'100%',
      right:'auto'
    },
  },
  backgroundA:{
    backgroundImage:`linear-gradient(90deg, 
      ${fade('#fff',0.85)}, 
      ${fade('#fff',0.2)}
    ), url(${sideImage})`,
  },
  backgroundB:{
    backgroundImage:`linear-gradient(90deg, 
      ${fade('#fff',0.85)}, 
      ${fade('#fff',0.2)}
    ), url(${sideImage})`,
  },
  backgroundC:{
    backgroundImage:`linear-gradient(90deg, 
      ${fade('#fff',0.85)}, 
      ${fade('#fff',0.2)}
    ), url(${sideImage})`,
  },
  emptySpace:{
    height:`calc(${totalHeight}px - 100vh)`,
    position:'absolute',
    top:0,
    left:'50%',
    width:'1px',
    visibility:'hidden',
    boxSizing:'border-boxSizing'
  },
  container:{
    width:'100%',
    height:'100vh',
    position:'sticky',
    top:'0px',
    zIndex:4,
    
  },
  subcontainer:{
    
  },
  containerB:{
    background:'linear-gradient(90deg, rgba(52,33,107,1) 0%, rgba(84,0,166,1) 100%)',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 2500,
    }),
  },
  imageContainer:{
    position:'absolute',
    width:'100%',
    height:'100vh',
    background:'transparent',

  },
  image:{
    width:'100%',
  },
  letter:{
    fontFamily:'Avenir',
    fontSize:140,
    fontWeight:600,
    padding:0,
    margin:0,
  },
  txtWrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-70%, -50%)',
    zIndex:10
  },
  textB_wrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%, -50%)',
    zIndex:10
  },
  textB_container:{ 
    width:1200,
    fontFamily:'Avenir',
    fontSize:120,
    fontWeight:600,
  },
  overlay:{
     
    //width:700, 
    //height:'100vh',
    //top:0,
    //right:0,
    //position:'absolute',
    //background:`linear-gradient(90deg, rgba(214,65,137,0.5) 0%, rgba(214,65,137,0.14049369747899154) 100%)`
  },
  screenWrapperA:{
    overflowX:'hidden', 
    background:'linear-gradient(90deg, rgba(52,33,107,1) 0%, rgba(84,0,166,1) 100%);',
  },
  logo:{
    width:400,
    paddingBottom:0,
    //background:'red',
    [theme.breakpoints.down('sm')]: {
      width:300
    },
  }, 
  logo_container:{
    position:'absolute',
    top:50,
    left:40,
    display:'flex',
    alignItems:'center',
    [theme.breakpoints.down('sm')]: {
      display:'inline',
      top:50
    },
    //background:'green'
  },
  title:{
    color:'white',
    marginTop:44,
    marginLeft:8,
    [theme.breakpoints.down('sm')]: {
      marginLeft:0
    },
  },
  paragrahp_container:{
    //position:'relative',
    //zIndex:99,
    marginTop:'100vh',
    width:'100%',
    height:'100vh',
    background:'green'
  },
  contact_root:{
    background:'black',
    //position:'absolute',
    padding:'200px 0px 100px',
    height:'100vh'
  },
  contact_logo_container:{
    display:'flex',
    justifyContent:'center',
    width:'100%',
    //margin:'200px 0px'
  },
  contact_info_container:{
    paddingLeft:200,
    marginTop:200,
    //background:'red',
    color:theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      padding:'0px 32px',
      marginTop:100
    },
   
    
    
  },
  contact_logo:{
    width:200,
  }
})

export default withStyles(styles)(SectionF)


//lo que estaba dentro de root
{/* <div className={classes.emptySpace} />
        <div className={classes.container} style={{overflow:'hidden', }}>
          <div>
            <Fade in={scroll_position >= offset && scroll_position < range_first_screen.max  + offset} timeout={750}>
              <Slide direction='right' in={scroll_position > offset }  timeout={1200} >
                <div className={classes.logo_container}>
                    <img src={logo} alt='' className={classes.logo}/>
                    <Typography variant='h2' className={classes.title} >Insights</Typography>
                </div>
              </Slide>  
            </Fade>    
            <ScreenB content={content.insights}
              scroll={scroll_position} range={range_first_screen} offset={offset}/>

            <Fade in={scroll_position >= offset + 2000} timeout={1200}>
              <div className={classes.contact_root}>  
                <Slide direction='left' in={scroll_position > offset + 2000}  timeout={1200}>
                  <div className={classes.contact_logo_container}>
                      <img src={logo} alt='' className={classes.contact_logo}/>
                  </div>
                </Slide> 
                <div className={classes.contact_info_container}>   
                  <a className={classes.link} target='_blank' href='info@althar.com' >info@althar.com</a>
                  <div style={{color:'white', marginTop:40}}>
                    <Typography>Palenque 369, Colonia Narvarte, Benito Juárez</Typography>
                    <Typography >Ciudad de México, CP 03400</Typography>
                    <Typography>+52 55 5335 1351</Typography>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div> */}