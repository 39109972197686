import React from 'react'
import { withStyles, fade, Slide } from '@material-ui/core';
import cx from 'classnames'
import ScreenA from './components/ScreenA';
import ScreenB from './components/ScreenB';
import sideImage from  '../../../../assets/sideImage.jpg'
import SideContent from './components/SideContent';
import useSectionD from './useSectionD';

import imageHealth from '../../../../assets/PNG/PNG/health.png'
import imageTech from '../../../../assets/PNG/PNG/WEBnegro_44.jpg'
import imageStrategy from '../../../../assets/PNG/PNG/strategy.jpg'
import imageStrategy2 from '../../../../assets/PNG/PNG/strategy2.jpg'
import { useEffect } from 'react';



const totalHeight = 6200
//const totalHeight = 5000

/* const range_first_screen = {min:0, max:2800, offset:0}
const range_second_screen = {min:0, max:1000, offset:1000}
const range_third_screen = {min:0, max:1400, offset:2000}
const range_fourth_screen = {min:0, max:1800, offset:3400}
const range_fifth_screen = {min:0, max:1500, offset:6400}
const range_side_screen = {min:0, max:5200, offset:1000} */

const range_first_screen = {min:0, max:1000, offset:500, }
const range_second_screen = {min:0, max:1400, offset:1500, time:1100, time2:600, timemin:0, timemin2:0}
const range_third_screen = {min:0, max:1400, offset:2900, time:1200, time2:600, timemin:0, timemin2:-500}
const range_fourth_screen = {min:0, max:1800, offset:4300, time:1900, time2:1200, timemin:300, timemin2:-400}
//const range_fifth_screen = {min:0, max:1500, offset:6100}
const range_side_screen = {min:0, max:5200, offset:1500}


const SectionD = ({classes, language, scroll_position, offset}) => {


  const content = contentData[language]
  const {actions} = useSectionD({scroll_position, offset})
  
  let fade_first_screen = actions.onFadeText(range_first_screen)


  return( 
    <div className={cx({
      [classes.root]:true,
      [classes.rootB]: scroll_position > offset + range_second_screen.min + range_second_screen.offset 

    })} id={3}> 
      
      <div className={classes.emptySpace} />
      {/* <div className={classes.hidden}></div> */}
      <div className={classes.container} style={{overflow:'hidden', }}>
        
        <div className={classes.subcontainer} style={{
          overflow:'hidden',
          }}>
            <div className={cx({
              [classes.backgroundImage]:true,
              [classes.backgroundA]:scroll_position > offset + range_second_screen.min + range_second_screen.offset && scroll_position <= offset + range_second_screen.max + range_second_screen.offset,
              [classes.backgroundB]:scroll_position > offset + range_third_screen.min + range_third_screen.offset && scroll_position <= offset + range_third_screen.max + range_third_screen.offset,
              [classes.backgroundC]:scroll_position > offset + range_fourth_screen.min + range_fourth_screen.offset && scroll_position <= offset + range_fourth_screen.max + range_fourth_screen.offset,
              })} 
            style={{visibility:scroll_position > offset + range_second_screen.min + range_second_screen.offset ? 'visible' : 'hidden'}}/>
            {/* <div className={cx({
              [classes.overlay]:true,
              })} 
              
            /> */}
            <ScreenA language={language} scroll={scroll_position} range={range_first_screen} offset={offset} fade_in={fade_first_screen}/>
            {/************** MAIN SECTION CONTENT *********************/}
            <ScreenB color={'#569380'}content={content.sectionD}
              scroll={scroll_position} range={range_second_screen} offset={offset}/>
            <ScreenB color={'#311C65'}content={content.sectionB}
              scroll={scroll_position} range={range_third_screen} offset={offset}/>
            <ScreenB color={'#6AB5DE'}content={content.sectionF}
              scroll={scroll_position} range={range_fourth_screen} offset={offset}/>
            {/* <ScreenB color={'#C1428E'}content={content.sectionE}
              scroll={scroll_position} range={range_fifth_screen} offset={offset}/>   */} 
        </div>
      </div>
        <SideContent scroll={scroll_position} range={range_side_screen} offset={offset} language={language}/> 
    </div>
  )

}

const contentData = {
  english:{
    sectionB:{
      title:'Technology',
      data:'Transforming industries through disruptive technology services in software, hardware, telemedicine, artificial intelligence.'
    },
    sectionC:{
      title:'Consulting',
      data:'Raise your business to the next level with groundbreaking strategy, fresh technologies and innovation advisors that help drive business value.'
    },
    sectionD:{
      title:'Health',
      data:'From the medical affairs office to the doctor’s office, we help clients deliver more effective, efficient and affordable healthcare with high-impact healthcare innovative services.'
    },
    sectionE:{
      title:'Commercial',
      data:'We help companies take advantage of the latest tools, techniques and expertise in order to reach solutions focused on scalable resources, precision insights, and actionable ideas.'
    },
    sectionF:{
      title:'Strategy',
      data:'Raise your business to the next level with groundbreaking strategy, fresh technologies and innovation advisors that help drive business value.'
    }
  }
}

const styles = theme => ({
  generalContainer:{
    width:1400,
    margin:'auto',
    height:'100vh',
    position:'relative',
    boxSizing:'border-box',
    [theme.breakpoints.only('lg')]: {
      width:1100,
    },
    [theme.breakpoints.only('md')]: {
      width:'100%',
      padding:'0px 32px'
    },
    [theme.breakpoints.only('sm')]: {
      width:'100%',
      padding:'0px 32px'
    },
    [theme.breakpoints.only('xs')]: {
      width:'100%',
      padding:'0px 32px'
    },
  },
  root:{
    height:`${totalHeight}px`,
    position:'relative',
    background:theme.palette.primary.main,
    //background:'green',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
    [theme.breakpoints.down('xs')]: {
      height:5000
    },
   
  },
  rootB:{
    background:'transparent',
    //background:'red',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
  },
  hidden:{
    [theme.breakpoints.down('sm')]: {
      position:'fixed',
      top:0,
      left:0, 
      height:400,
      width:'100%',
      background:'white',
      zIndex:200
      //zIndex:300
    },
  },
  backgroundImage:{
    position:'absolute',
    width:600,
    height:'100vh',
    top:0,
    right:0,
    filter:'blur(1px)',
    backgroundImage:`linear-gradient(90deg, 
      ${fade('#fff',0.85)}, 
      ${fade('#fff',0.35)}
    ), url(${imageStrategy2})`,
    //background:'red',
    backgroundSize:'cover',
    [theme.breakpoints.only('lg')]: {
      width:500,
    },
    [theme.breakpoints.only('md')]: {
      width:370,
    },
    [theme.breakpoints.only('sm')]: {
      marginTop:400,
      width:'100%',
      right:'auto'
    },
    [theme.breakpoints.only('xs')]: {
      marginTop:400,
      width:'100%',
      right:'auto'
    },
  },
  backgroundA:{
    backgroundImage:`linear-gradient(90deg, 
      ${fade('#fff',0.85)}, 
      ${fade('#fff',0.2)}
    ), url(${imageHealth})`,
  },
  backgroundB:{
    backgroundImage:`linear-gradient(90deg, 
      ${fade('#fff',0.85)}, 
      ${fade('#fff',0.2)}
    ), url(${sideImage})`,
  },
  backgroundC:{
    backgroundImage:`linear-gradient(90deg, 
      ${fade('#fff',0.85)}, 
      ${fade('#fff',0.2)}
    ), url(${imageStrategy})`,
  },
  emptySpace:{
    height:`calc(${totalHeight}px - 100vh)`,
    position:'absolute',
    top:0,
    left:'50%',
    width:'1px',
    visibility:'hidden',
    boxSizing:'border-boxSizing',
    //background:'red'
  },
  container:{
    width:'100%',
    height:'100vh',
    position:'sticky',
    top:'0px',
    //background:'red'
  },
  subcontainer:{
    //background:'red'
  },
  containerB:{
    background:'linear-gradient(90deg, rgba(52,33,107,1) 0%, rgba(84,0,166,1) 100%)',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 2500,
    }),
  },
  imageContainer:{
    position:'absolute',
    width:'100%',
    height:'100vh',
    background:'transparent',

  },
  image:{
    width:'100%',
  },
  letter:{
    fontFamily:'Avenir',
    fontSize:140,
    fontWeight:600,
    padding:0,
    margin:0,
  },
  txtWrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-70%, -50%)',
    zIndex:10
  },
  textB_wrapper:{
    position:'absolute',
    top:'50%',
    left:'50%',
    transform: 'translate(-50%, -50%)',
    zIndex:10
  },
  textB_container:{ 
    width:1200,
    fontFamily:'Avenir',
    fontSize:120,
    fontWeight:600,
  },
  overlay:{
    width:'100%',
    height:500,
    background:'red'
  },
  screenWrapperA:{
    overflowX:'hidden', 
    background:'linear-gradient(90deg, rgba(52,33,107,1) 0%, rgba(84,0,166,1) 100%);',
  },
})

export default withStyles(styles)(SectionD)