import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import image from '../../assets/ALTHAR_Serverless-Computing.png'

const Serverless = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <div className={classes.title_container} >
                <Typography className={classes.title} variant='h2'>Serverless</Typography>
                <Typography className={classes.title} variant='h2'>Computing</Typography>
            </div>
            <div className={classes.challenge}>
                <Typography className={classes.subtitle} variant='subtitle1'>Challenge.</Typography>
                <Typography className={classes.text}>
                    Help CeluHealth to be faster, without losing agility
                    and with lower total cost of ownership for the first
                    native digital health ecosystem in Latin America.
                </Typography>
            </div>
            <div className={classes.solution}>
                <Typography className={classes.subtitle} variant='subtitle1'>Solution.</Typography>
                <Typography className={classes.text}>
                    Serverless is the native architecture of the cloud that
                    enables you to shift more of your operational responsibilities
                    to Medinube, increasing agility and innovation. Medinube allows
                    you to build and run applications and services without
                    thinking about servers and being 100% HIPAA compliance.
                </Typography>
            </div>
            <div className={classes.result}>
                <Typography className={classes.subtitle} variant='subtitle1'>Result.</Typography>
                <Typography className={classes.text}>
                    CeluHealth runs a 100% serverless computing operation, with
                    flexible and autometed scaling with high availability. 
                    Working for more than 500k patients, and nearly 2 million
                    monthly transactions inside the ecosystem.
                </Typography>
            </div>
            {/* <div className={classes.img_container}>
                <img src={image} alt='' className={classes.img} />
            </div> */}
            <img src={image} alt='' className={classes.img} />
            <div className={classes.line_container} style={{top:'42%', left:'18%'}}>
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>
                <div className={classes.line} style={{width:240, marginTop:16, marginLeft:-7}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'48%', left:'16%'}}>
                <div className={classes.line2} style={{width:30, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:320, marginTop:-10, marginLeft:-3}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'32%', right:'12%'}}>
                <div className={classes.line} style={{width:300, marginTop:-16, marginRight:-5}}></div> 
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>        
            </div>
        </div>
    )
}

export default Serverless

const useStyles = makeStyles(theme => ({
    root:{
      background:theme.palette.secondary.main,
      //background:'red',
      height:'100vh',
      padding:'5%',
      color:'white',
      position:'relative'
    },
    title_container:{
        position:'absolute',
        right:'5%',
        top:'10%',
        textAlign:'right',
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            right:'initial',
            top:'auto',
            textAlign:'left',
            marginBottom:12,
            marginTop:50
        },
    },
    title:{
        lineHeight:0.9,
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
          },
    },
    subtitle:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.2rem'
          },
    },
    text:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.9rem'
          },
    },
    line_container:{
        position:'absolute',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
    },
    line:{
        height:0.5,
        background:'white',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        //width:180,
    },
    line2:{
        height:0.5,
        background:'white',
        width:60,
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        
    },
    challenge:{
        position:'absolute',
        top:'20%',
        //bottom:'50%',
        left:'5%',
        //paddingTop:'50%',
        //paddingTop:'5%',
        maxWidth:360,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'green',
            top:'auto',
            left:0,
            marginTop:12
            
          },
        
    },
    solution:{
        position:'absolute',
        top:'35%',
        right:'5%',
        maxWidth:280,
        textAlign:'right',
        //background:'black'
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'blue',
            top:'auto',
            right:0,
            left:0,
            textAlign:'left',
            margin:'12px 0px'
        },
        
    },
    result:{
        position:'absolute',
        //top:'63%',
        top:'50%',
        //right:'30%',
        left:'12%',
        maxWidth:340,
        [theme.breakpoints.down('md')]:{
            //left:'5%'
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:'auto',
            left:0, 
        },
        
    },
    img_container:{
        position:'absolute',

        top:-10,
        bottom:10,
        height:100,
        //left:'50%',
        background:'black'
    },
    img:{
        //width:'100%',
        //height:'100%',
        //transform:'rotate(10deg)',
        objectFit:'cover',
        position:'absolute',
        top:'-10%',
        left:'32%',
        //right:'15%',
        //right:'20%',
        //background:'black',
        width:'40%',
        [theme.breakpoints.down('md')]:{
            //transform:'scale(0.8) rotate(25deg)',
            //right:'10%',
            //top:-20
        },
        [theme.breakpoints.down('sm')]:{
            //transform:'scale(0.8) rotate(25deg)',
            left:'55%',
            top:'35%'
        },
        [theme.breakpoints.down('xs')]:{
            display:'none'
        }

        //objectPosition:'center'
        //bottom:20
    }
    
  }))