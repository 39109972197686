import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import image from '../../assets/ALTHAR_WEB_Advisory-Boards.png'

const Advisory = () =>{

    const classes = useStyles()

    return(
        <div className={classes.root}>
            <div className={classes.title_container}>
                <Typography className={classes.title} variant='h2'>Advisory Boards</Typography>
            </div>   
            <div className={classes.challenge}>
                <Typography className={classes.subtitle} variant='subtitle1'>Challenge.</Typography>
                <Typography className={classes.text}>
                    To know the state of the art and 
                    real situation of
                    the “Cuadro Básico” approval 
                    processes in the actual turbulent 
                    healthcare system in Mexico.
                </Typography>
            </div>
            <div className={classes.solution}>
                <Typography className={classes.subtitle} variant='subtitle1'>Solution.</Typography>
                <Typography className={classes.text}>
                    We designed and 
                    developed a 
                    special advisory 
                    board,
                    with a very 
                    interactive 
                    methodology, 
                    taking care of 
                    being in 
                    compliance with 
                    the pharma 
                    regulations.
                </Typography>
            </div>
            <div className={classes.result}>
                <Typography className={classes.subtitle} variant='subtitle1'>Result.</Typography>
                <Typography className={classes.text}>
                    Based on the results, our 
                    clients have been able to 
                    include their products with 
                    very innovative shared risk
                    programs and new 
                    pharmacoeconomic models.
                </Typography>
            </div>
            {/* <div className={classes.img_container}>
                <img src={image} alt='' className={classes.img} />
            </div> */}
            <img src={image} alt='' className={classes.img} />
            <div className={classes.line_container} style={{top:'37%', left:'18%'}}>
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>
                <div className={classes.line} style={{width:70, marginTop:16, marginLeft:-7}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'55%', left:'5%'}}>
                <div className={classes.line2} style={{width:30, transform:'rotate(-40deg)'}}></div>
                <div className={classes.line} style={{width:290, marginTop:-10, marginLeft:-3}}></div>     
            </div>
            <div className={classes.line_container} style={{top:'40%', right:'12%'}}>
                <div className={classes.line} style={{width:300, marginTop:-16, marginRight:-5}}></div> 
                <div className={classes.line2} style={{width:50, transform:'rotate(40deg)'}}></div>        
            </div>
        </div>
    )
}

export default Advisory

const useStyles = makeStyles(theme => ({
    root:{
      background:theme.palette.secondary.main,
      //background:'red',
      height:'100vh',
      padding:'5% 5% 0px',
      color:'white',
      position:'relative'
    },
    title_container:{
        position:'absolute',
        right:'5%',
        top:'10%',
        textAlign:'right',
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            right:'auto',
            top:'auto',
            textAlign:'left',
            marginBottom:12,
            marginTop:50
        },
    },
    title:{
        lineHeight:0.9,
        [theme.breakpoints.down('sm')]:{
            fontSize:'3rem'
          },
    },
    subtitle:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'1.2rem'
          },
    },
    text:{
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.9rem'
          },
    },
    line_container:{
        position:'absolute',
        display:'flex',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
    },
    line:{
        height:0.5,
        background:'white',
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        //width:180,
    },
    line2:{
        height:0.5,
        background:'white',
        width:60,
        [theme.breakpoints.down('md')]:{
            display:'none'
          },
        
    },
    challenge:{
        position:'absolute',
        //top:'35%',
        top:'10%',
        left:'5%',
        //paddingTop:'50%',
        //paddingTop:'5%',
        maxWidth:300,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'green',
            top:0,
            bottom:'auto',
            left:0,
            marginTop:12,
            maxWidth:'100%'
            
          },
        
    },
    solution:{
        position:'absolute',
        top:'45%',
        right:'5%',
        maxWidth:200,
        textAlign:'right',
        //background:'black'
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            //background:'blue',
            top:'auto',
            right:0,
            left:0,
            textAlign:'left',
            margin:'12px 0px'
        },
        [theme.breakpoints.down('xs')]:{
            maxWidth:'100%'
        },
        
    },
    result:{
        position:'absolute',
        //top:'63%',
        top:'60%',
        left:'3%',
        maxWidth:280,
        [theme.breakpoints.down('md')]:{
            left:'5%'
        },
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            top:'auto',
            left:0, 
            maxWidth:200
        },
        [theme.breakpoints.down('xs')]:{
            maxWidth:'100%'
        },
        
    },
    img_container:{
        position:'absolute',

        top:-10,
        bottom:10,
        height:100,
        //left:'50%',
        background:'black'
    },
    img:{
        //width:'100%',
        //height:'100%',
        //transform:'rotate(25deg)',
        objectFit:'cover',
        position:'absolute',
        top:'25%',
        left:'28%',
        //background:'black',
        width:'50%',
        [theme.breakpoints.down('md')]:{
           width:'40%',
           left:'32%',
           top:'30%'
        },
        [theme.breakpoints.down('sm')]:{     
            left:'40%',
            top:'40%'
        },
        [theme.breakpoints.down('xs')]:{
            display:'none'
        }

        //objectPosition:'center'
        //bottom:20
    }
    
  }))