import React from 'react'
import { makeStyles, Fade, withWidth, useTheme, useMediaQuery } from '@material-ui/core';
import cx from 'classnames'

const vector_size = {
  'xs': {width:494,height:153, offset_x:-120, scale:0.2},
  'sm': {width:494,height:153, offset_x:-70, scale:0.5},
  'md': {width:494,height:153, offset_x:20, scale:0.7},
  'lg': {width:494,height:153, offset_x:60, scale:0.85},
  'xl': {width:494,height:153, offset_x:100, scale:1},
}

const useStyles = makeStyles(theme => ({
  svgImage:{
    position:'absolute',
    width:'625px',//'625px',
    height:'197px',//'197px',
    top:'65%',
    left:'47%',
    transform:'translate(calc(-50% - 232px), calc(-50% - 98px)) scale(1)',
    zIndex:1,
    //transition: theme.transitions.create(['transform','width','height'], {
    //  easing: theme.transitions.easing.easeIn,
    //  duration: 1000,
    //}),
    //[theme.breakpoints.down('lg')]: {
    //  transform:'translate(calc(-50% + 22%), calc(-50% - 40%)) scale(0.85)',
    //},
    //[theme.breakpoints.down('md')]: {
    //  transform:'translate(calc(-50% + 10%), calc(-50% - 20%)) scale(0.60)',
    //},
    [theme.breakpoints.down('sm')]: {
      top:'45%'
    },
  },
  svgTransition:{
    transform:'translate(45%, -50%) scale(2.5)',
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeIn,
      duration: 1500,
    }),
    [theme.breakpoints.down('lg')]: {
      transform:'translate(calc(15% + 0%), calc(-50% - 0%)) scale(1.6)',
    },
  },
  svgTransition2:{
    top:'200%',
    left:'265%',
    transform:'translate(0%, 0%) scale(18)',
    transition: theme.transitions.create(['transform', 'top', 'left'], {
      easing: theme.transitions.easing.easeIn,
      duration: 1500,
    }),
    [theme.breakpoints.down('lg')]: {
      top:'50%',
      left:'50%',
      transform:'translate(375%, 450%) scale(11)',
    },
  }
}))

const MeetLogo =  props => {

  const {range, scroll, max_visibility, width, fade} = props
  const classes = useStyles()

  const logo_size = vector_size[width]

  let logo_scale = logo_size.scale
  let logo_offset_x = logo_size.width + logo_size.offset_x
  let logo_offset_y = logo_size.height*logo_scale/2

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  let s_offset = 0
  if(matches) s_offset = 300


  return(
   
      <div className={cx({
        [classes.svgImage]:true,
        [classes.svgTransition]:scroll >= range.max+range.offset ,
      })} 
        style={{
          visibility: scroll < max_visibility ? 'visible' : 'hidden',
          transform:`translate(calc(0% - ${logo_offset_x}px), calc(-50% - ${logo_offset_y}px)) scale(${logo_scale})`,
        }}
        >
           <Fade direction='right' in={scroll >= (range.min + range.offset - s_offset)} timeout={500}>
             <div>
              <SVGMeet fade={fade}/>
             </div>
           </Fade>
        
      </div>
    
    
  )
}

export default withWidth()(MeetLogo)


const SVGMeet = props => {
  return(
  <svg  x="0px" y="0px"
  width="494px" height="153px" opacity={props.fade}
	>
    <g>
      <path fill="#FFFFFF" d="M6.328,5.942h47.2l32.6,92.399h0.4l32.8-92.399h47v141.6h-31.2v-108.6h-0.4l-37.2,108.6h-23.8l-35.8-108.6
        h-0.4v108.6h-31.2V5.942z"/>
      <path fill="#FFFFFF" d="M283.325,130.742c-4.8,6.134-10.869,10.868-18.2,14.2c-7.334,3.331-14.934,5-22.8,5
        c-7.469,0-14.5-1.2-21.1-3.601c-6.6-2.399-12.334-5.831-17.2-10.3c-4.869-4.466-8.7-9.831-11.5-16.1c-2.8-6.266-4.2-13.266-4.2-21
        c0-7.731,1.4-14.731,4.2-21c2.8-6.266,6.631-11.632,11.5-16.101c4.866-4.466,10.6-7.899,17.2-10.3s13.631-3.6,21.1-3.6
        c6.931,0,13.231,1.199,18.9,3.6c5.666,2.4,10.466,5.834,14.4,10.3c3.931,4.469,6.966,9.835,9.101,16.101
        c2.131,6.269,3.199,13.269,3.199,21v9.399h-69.6c1.2,5.734,3.8,10.3,7.8,13.7s8.931,5.1,14.8,5.1c4.931,0,9.1-1.1,12.5-3.3
        c3.4-2.2,6.365-5.031,8.9-8.5L283.325,130.742z M257.925,87.942c0.131-5.066-1.535-9.4-5-13c-3.469-3.601-7.935-5.4-13.4-5.4
        c-3.334,0-6.269,0.534-8.8,1.6c-2.534,1.069-4.7,2.435-6.5,4.101c-1.8,1.669-3.2,3.6-4.2,5.8s-1.568,4.5-1.7,6.9H257.925z"/>
      <path fill="#FFFFFF" d="M398.124,130.742c-4.801,6.134-10.869,10.868-18.201,14.2c-7.334,3.331-14.934,5-22.799,5
        c-7.469,0-14.5-1.2-21.101-3.601c-6.601-2.399-12.335-5.831-17.2-10.3c-4.869-4.466-8.699-9.831-11.5-16.1
        c-2.8-6.266-4.199-13.266-4.199-21c0-7.731,1.399-14.731,4.199-21c2.801-6.266,6.631-11.632,11.5-16.101
        c4.865-4.466,10.6-7.899,17.2-10.3s13.632-3.6,21.101-3.6c6.931,0,13.23,1.199,18.899,3.6c5.665,2.4,10.466,5.834,14.399,10.3
        c3.932,4.469,6.967,9.835,9.101,16.101c2.132,6.269,3.2,13.269,3.2,21v9.399h-69.6c1.199,5.734,3.799,10.3,7.799,13.7
        s8.932,5.1,14.801,5.1c4.932,0,9.1-1.1,12.5-3.3s6.365-5.031,8.9-8.5L398.124,130.742z M372.724,87.942c0.131-5.066-1.535-9.4-5-13
        c-3.469-3.601-7.935-5.4-13.4-5.4c-3.334,0-6.269,0.534-8.8,1.6c-2.534,1.069-4.7,2.435-6.5,4.101c-1.8,1.669-3.2,3.6-4.2,5.8
        s-1.568,4.5-1.699,6.9H372.724z"/>
      <path fill="#FFFFFF" d="M487.121,74.341h-26.399v32.4c0,2.669,0.131,5.1,0.399,7.3c0.266,2.2,0.866,4.1,1.8,5.7
        c0.932,1.6,2.366,2.834,4.301,3.7c1.931,0.868,4.5,1.3,7.699,1.3c1.601,0,3.7-0.166,6.301-0.5c2.6-0.331,4.565-1.101,5.899-2.3v25
        c-3.334,1.199-6.8,2-10.399,2.399c-3.601,0.4-7.135,0.601-10.601,0.601c-5.068,0-9.734-0.535-14-1.601
        c-4.269-1.065-8-2.766-11.2-5.1c-3.199-2.331-5.699-5.366-7.5-9.101c-1.8-3.73-2.699-8.266-2.699-13.6v-46.2h-19.2v-24h19.2v-28.8
        h30v28.8h26.399V74.341z"/>
    </g>
  </svg>
  )
}